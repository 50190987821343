import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { isEmpty } from "lodash";

import * as notifications from "../../../../notifications";
import { FormCollapsible, Select, BooleanSelect, PercentageInput } from "components/forms";
import {
  createAccountHierarchySplit,
  editAccountHierarchyAsASplit,
  editAccountHierarchySplit,
  showModal,
} from "actions";
import {
  activeAccountIdSelector,
  activeHierarchyIdSelector,
  activeHierarchySelector,
  activeSplitSelector,
  activeSplitIdSelector,
  managementChoicesSelector,
  userChoicesSelector,
} from "reducers";
import { partial } from "utils";
import WideSaveButton from "components/buttons/WideSaveButton";
import {
  ACCOUNT_CHANGE_ACTIVE_HIERARCHY_MODAL,
  ACCOUNT_WARNING_UPDATE_HIERARCHY_MODAL,
} from "../../../../components/modals";

const HierarchySplitForm = ({ activeHierarchy, activeSplit, choices, ...props }) => {
  if (!choices) {
    return null;
  }

  let initialLeadValue = false;
  if (activeSplit) {
    initialLeadValue = activeSplit?.lead;
  }

  const isHierarchy = activeSplit && activeSplit.model === "Hierarchy";
  const disableSplitAndLead =
    isHierarchy && Array.isArray(activeHierarchy?.splits) && activeHierarchy.splits.length === 1;

  return (
    <Formik
      enableReinitialize
      initialValues={{ lead: initialLeadValue, ...activeSplit }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        let method = isEmpty(activeSplit)
          ? props.createAccountHierarchySplit
          : partial(props.editAccountHierarchySplit, props.splitId);

        values.accountId = props.activeAccountId;
        values.hierarchyId = props.activeHierarchyId;
        values.splitId = props.splitId;
        try {
          if (isHierarchy) {
            // If the selected element to edit is the default split, override the method
            method = props.editAccountHierarchyAsASplit;
            await method(values);
          } else {
            await method(values);
          }
        } catch (error) {
          if (error.response.data.unique_active_hierarchy) {
            props.showModal(ACCOUNT_CHANGE_ACTIVE_HIERARCHY_MODAL, {
              hierarchyValues: { ...values, id: activeHierarchy.id },
            });
          } else if (error.response.data.update_hierarchy_warning) {
            props.showModal(ACCOUNT_WARNING_UPDATE_HIERARCHY_MODAL, {
              values,
              method,
            });
          } else {
            setErrors(error.response.data);
            notifications.error("Error editing Hierarchy");
          }
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="Split Detail">
            <Form.Row>
              <Select
                label="Sales Representative"
                name="sales_representative"
                placeholder="Select Sales Rep"
                options={props.salesRepChoices?.getChoices({ sortByLabel: true }) || []}
              />
              <Select
                label="Sales Manager"
                name="sales_manager"
                placeholder="Select Sales Manager"
                options={props.salesRepChoices?.getChoices({ sortByLabel: true }) || []}
              />
              <Select
                label="Nat Acc Manager"
                name="national_account_manager"
                placeholder="Select Nat. Acc. Manager"
                options={props.salesRepChoices?.getChoices({ sortByLabel: true }) || []}
              />
              <Select
                label="National Account"
                name="national_account"
                placeholder="Select National Account"
                options={props.nationalAccountChoices?.getChoices({ sortByLabel: true }) || []}
              />
            </Form.Row>
            <Form.Row>
              <PercentageInput label="Split %" name="split_value" disabled={disableSplitAndLead} />
              <BooleanSelect
                label="Lead"
                name="lead"
                placeholder="Select Lead Status"
                defaultValue={{ value: false, label: "No" }}
                disabled={disableSplitAndLead}
              />
            </Form.Row>
            <WideSaveButton submitRef={props.submitRef} />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  return {
    choices,
    activeAccountId: activeAccountIdSelector(state),
    activeHierarchy: activeHierarchySelector(state) || {},
    activeHierarchyId: activeHierarchyIdSelector(state),
    activeSplit: activeSplitSelector(state),
    splitId: activeSplitIdSelector(state),
    userChoices: userChoicesSelector(state),
    salesRepChoices: choices.case_sales_representatives,
    nationalAccountChoices: choices.national_accounts,
  };
};

export default connect(mapStateToProps, {
  createAccountHierarchySplit,
  editAccountHierarchySplit,
  editAccountHierarchyAsASplit,
  showModal,
})(HierarchySplitForm);
