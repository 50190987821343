import { useEffect } from "react";
import { findPreviousAndNext } from "utils";

const usePaginationNavigation = ({
  entryType,
  entries,
  entryInfo,
  fetchPageAndSelectEntry,
  selectEntry,
  canPreviousPage,
  canNextPage,
  entriesSelector,
}) => {
  const { previous, next } = findPreviousAndNext(entries, entryInfo);

  const onClickPreviousPage = () => {
    fetchPageAndSelectEntry(entryType, "PREVIOUS", selectEntry, entriesSelector);
  };

  const onClickNextPage = () => {
    fetchPageAndSelectEntry(entryType, "NEXT", selectEntry, entriesSelector);
  };

  const onClickPreviousEntry = () => {
    selectEntry(previous);
  };

  const onClickNextEntry = () => {
    selectEntry(next);
  };

  const handleKeyDown = event => {
    if (event.target.isContentEditable || event.target.tagName === "INPUT") return;

    if (event.key === "ArrowLeft") {
      if (previous) {
        onClickPreviousEntry();
      } else if (canPreviousPage) {
        onClickPreviousPage();
      }
    } else if (event.key === "ArrowRight") {
      if (next) {
        onClickNextEntry();
      } else if (canNextPage) {
        onClickNextPage();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [previous, next]); // Re-run effect when entries change

  return {
    onClickPreviousPage,
    onClickNextPage,
    onClickPreviousEntry,
    onClickNextEntry,
    previous,
    next,
  };
};

export default usePaginationNavigation;
