import React from "react";
import { connect } from "react-redux";

import { activeCarrierSelector } from "reducers";
import { FaArrowLeft, FaUpload } from "react-icons/fa";
import { PERMISSIONS, URLS } from "../../constants";
import { Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, TabGroup } from "../../components/Tabs";
import { CARRIER_FILE_MODAL } from "../../components/modals";
import { setShouldSave, showModal } from "actions";
import { selectCarrierFile } from "../../reducers/life_settlement/management/carriers";
import { isAdmin } from "permissions";
import BackToButton from "../../components/buttons/BackToButton";
import { ButtonSaveChanges } from "../../components/buttons";

const CarrierTopHeader = ({ carrierInfo, ...props }) => {
  const { id } = useParams();
  const path = `/carriers/${id}`;

  const AddFileButton = () => {
    return (
      <div style={{ display: "flex", justifyContent: "center", margin: 0 }}>
        <button
          className="btn btn--secondary navbar-button"
          onClick={async () => {
            props.selectCarrierFile(null);
            props.showModal(CARRIER_FILE_MODAL);
          }}
          style={{
            width: "100%",
          }}
        >
          <FaUpload /> Add File
        </button>
      </div>
    );
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "4rem",
        left: "0rem",
        width: "100%",
        zIndex: 25,
      }}
    >
      <Card style={{ border: "1px solid black", borderRadius: "0px" }}>
        <Card.Header
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            backgroundColor: "black",
          }}
        >
          <h5
            className="section-title navbar-title"
            style={{
              color: "white",
              alignItems: "center",
              marginBottom: 0,
              lineHeight: 2,
            }}
          >
            Carrier: {carrierInfo ? carrierInfo.name : ""}
          </h5>
          <div style={{ display: "flex" }}>
            <BackToButton entity="Carriers" url={URLS.CARRIERS} />;{props.hasAdminPermission && <AddFileButton />}
            {props.hasAdminPermission && <ButtonSaveChanges saveFn={props.saveFn} />}
          </div>
        </Card.Header>
      </Card>
      <h3 className={"header tertiary"}>
        <TabGroup
          style={{
            width: "100%",
          }}
        >
          <Tab link={`${path}/overview/`}>Carrier Overview</Tab>
          <Tab link={`${path}/files/`}>Carrier Files</Tab>
          <Tab link={`${path}/product/`}>Carrier Product Detail</Tab>
        </TabGroup>
      </h3>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    hasAdminPermission: isAdmin(state),
    carrierInfo: activeCarrierSelector(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
  setShouldSave,
  selectCarrierFile,
})(CarrierTopHeader);
