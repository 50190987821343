import React from "react";
import { connect } from "react-redux";
import { IconContext } from "react-icons";
import { HiDownload } from "react-icons/hi";
import { BiRotateRight, BiShow } from "react-icons/bi";
import { AiOutlineDelete } from "react-icons/ai";

import { CONFIRM_FILE_DELETE_MODAL, CONFIRM_FILE_ROTATION_MODAL } from "components/modals";
import { showModal, downloadFile } from "actions";

import IconButton from "components/IconButton";
import FileIcon from "./FileIcon";
import { getViewUrl } from "../utils";

const File = ({ file, ...props }) => {
  if (file === null || !file?.name) {
    return null;
  }

  let viewUrl = getViewUrl(file);
  return (
    <div className="file-display" style={{ ...props.style }}>
      <div className="file-icon">
        <IconContext.Provider value={{ size: "2rem" }}>
          <FileIcon fileName={file.name} />
        </IconContext.Provider>
      </div>
      <div className="file-details">
        <div className="file-name">{file.name}</div>
        <div className="size-and-actions">
          <small className="text-muted">{file.size}</small>
          <div className="action-icons">
            {props.rotateFile && (
              <div
                className="icon"
                onClick={() =>
                  props.showModal(CONFIRM_FILE_ROTATION_MODAL, {
                    onConfirm: () => props.rotateFile(),
                  })
                }
              >
                <IconButton Icon={BiRotateRight} iconConfig={{ size: "1.2rem" }} tooltip="Auto-Rotate File" />
              </div>
            )}
            {props.fileDownloadUrl ? (
              <div className="icon" onClick={() => props.downloadFile(props.fileDownloadUrl, file.name)}>
                <IconButton Icon={HiDownload} iconConfig={{ size: "1.2rem" }} tooltip="Download File" />
              </div>
            ) : null}
            <div className="icon" onClick={() => window.open(viewUrl, "_blank")}>
              <IconButton Icon={BiShow} iconConfig={{ size: "1.2rem" }} tooltip="View File" />
            </div>
            {props.deleteFile ? (
              <div
                className="icon"
                onClick={() =>
                  props.showModal(CONFIRM_FILE_DELETE_MODAL, {
                    onConfirm: () => props.deleteFile(),
                  })
                }
              >
                <IconButton Icon={AiOutlineDelete} iconConfig={{ size: "1.2rem" }} tooltip="Delete File" />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { showModal, downloadFile })(File);
