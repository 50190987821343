import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { TASK_MODAL } from "components/modals";
import {
  activeRecordSelector,
  activeRecordTypeSelector,
  activeTabSelector,
  tabBasedRecordsSelector,
} from "../../reducers";
import { TaskTab } from "./constants";
import AccountTaskForm from "../accounts/information/tasks/AccountTaskForm";
import LeadTaskForm from "../leads/tasks/LeadTaskForm";
import CaseTaskForm from "../cases/caseTasks/CaseTaskForm";
import CaseFileForm from "../cases/caseFiles/CaseFileForm";
import {
  clearActiveCaseFile,
  fetchBeneficiaries,
  fetchOwners,
  fetchInsured,
  fetchCaseFilesByCaseDocumentId,
  selectActiveRecordId,
  selectActiveRecordType,
  selectActiveTask,
  parseActiveSectionToEndpoint,
} from "../../actions";
import TaskModalArrows from "./TaskModalArrows";
import { calculateEntries } from "./utils";
import { ENDPOINTS_AND_PAGE_TO_PAGE_SELECTOR_MAPPING } from "../../actions/pagination/constants";
import { NavButton } from "../../components/TopHeaderButtons";

const TaskModal = ({ taskType, record, onclick, nextEntry, prevEntry, ...props }) => {
  const [loading, setLoading] = React.useState(false);
  return (
    <FullPageModal modalType={TASK_MODAL} style={{ content: { overflow: "visible" } }}>
      <TaskModalArrows
        onclick={onclick}
        nextEntry={nextEntry}
        prevEntry={prevEntry}
        setLoading={setLoading}
        canNextPage={props.canNextPage}
        canPreviousPage={props.canPreviousPage}
        endpoint={props.endpoint}
      />
      {taskType === TaskTab.CASE && !loading && <CaseTaskForm record={record} />}
      {taskType === TaskTab.CASE_REQUIREMENT && !loading && <CaseFileForm record={record} onlyBaseForm={true} />}
      {taskType === TaskTab.ACCOUNT && !loading && <AccountTaskForm record={record} />}
      {taskType === TaskTab.LEAD && !loading && <LeadTaskForm record={record} />}
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  const tab = activeTabSelector(state);
  const record = activeRecordSelector(state);
  const records = tabBasedRecordsSelector(state);
  const endpoint = parseActiveSectionToEndpoint(tab);
  let pageCount = ENDPOINTS_AND_PAGE_TO_PAGE_SELECTOR_MAPPING[endpoint]?.PAGES(state) || 0;
  let currentPage = ENDPOINTS_AND_PAGE_TO_PAGE_SELECTOR_MAPPING[endpoint]?.CURRENT(state) || 0;

  const { prevEntry, nextEntry } = calculateEntries(record, records);

  return {
    taskType: activeRecordTypeSelector(state),
    canPreviousPage: currentPage > 1,
    canNextPage: currentPage < pageCount,
    endpoint,
    record,
    prevEntry,
    nextEntry,
  };
};

const mapDispatchToProps = dispatch => ({
  onclick: record => {
    dispatch(selectActiveRecordType(record.type));
    dispatch(selectActiveRecordId(record.id));

    if (record.type === TaskTab.CASE_REQUIREMENT) {
      dispatch(clearActiveCaseFile());
      dispatch(fetchInsured(record.case_id));
      dispatch(fetchOwners(record.case_id));
      dispatch(fetchBeneficiaries(record.case_id));
      dispatch(fetchCaseFilesByCaseDocumentId(record.case_document));
    }

    dispatch(selectActiveTask(record));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TaskModal);
