import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { ACCOUNT_BACKGROUND_DOCUMENTATION_MODAL } from "components/modals";
import BackgroundDocumentationForm from "./BackgroundDocumentationForm";
import { activeBackgroundDocumentationSelector } from "reducers";
import SingleFileDropZone from "../../../../components/SingleFileDropZone";
import { uploadAccountFile } from "../../../../actions";
import { isAdmin } from "../../../../permissions";

const BackgroundDocumentationModal = ({ activeBackgroundDocumentation, hasAdminPermission, ...props }) => {
  let isSaved = activeBackgroundDocumentation && activeBackgroundDocumentation.id;
  const submitRef = React.useRef();
  let title = isSaved ? "Edit Background Documentation" : "New Background Documentation";
  const file = activeBackgroundDocumentation?.file;

  return (
    <FullPageModal
      modalType={ACCOUNT_BACKGROUND_DOCUMENTATION_MODAL}
      title={title}
      style={{ content: { overflow: "visible" } }}
    >
      <BackgroundDocumentationForm submitRef={submitRef} />
      {isSaved && hasAdminPermission ? (
        <div>
          <SingleFileDropZone
            currentFile={file?.name ? file : null}
            uploadFile={file => {
              props.uploadAccountFile(file, "BACKGROUND_DOCUMENTATION");
            }}
            fileDownloadUrl={`/api/background_documentation/${activeBackgroundDocumentation.id}/download_file/`}
          />
        </div>
      ) : null}
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    activeBackgroundDocumentation: activeBackgroundDocumentationSelector(state) || {},
    hasAdminPermission: isAdmin(state),
  };
};

export default connect(mapStateToProps, { uploadAccountFile })(BackgroundDocumentationModal);
