import React from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { isEmpty } from "lodash";

import * as notifications from "../../../notifications";
import {
  Select,
  EditorField,
  FormCollapsible,
  SubmitButton,
  DateInput,
  TextInput,
  DateTimeInput,
  HyperLinkText,
  Collapsible,
} from "components/forms";
import {
  createLeadTask,
  editLeadTask,
  fetchManagerAgents,
  fetchRecords,
  showModal,
  hideModal,
  editLeadFromLeadTaskForm,
} from "actions";
import {
  activeLeadIdSelector,
  activeLeadTaskSelector,
  activeTabSelector,
  storedRecordsFiltersSelector,
  storedLeadRecordsFiltersSelector,
  userChoicesSelector,
} from "reducers";
import { partial } from "../../../utils";
import { TASK_STATUS, TASK_TYPES } from "../../accounts/constants";
import { addThreeBusinessDays } from "../../../reducers/utils";
import { useLocation } from "react-router-dom";
import { FETCH_RECORDS_BY_TYPE_MAP, TaskTab } from "../../records/constants";
import { LEAD_STATUS_CHOICES_MAP, leadStatusChoices, URLS } from "../../../constants";
import { LEAD_TASK_MODAL, SEND_FUNDER_EMAIL_MODAL } from "../../../components/modals";
import { getTaskObjId } from "../../records/utils";
import { SendLeadEmailFromTemplateIcon } from "../../accounts/emails/SendEmailFromTemplateIcon";

const LeadTaskForm = ({ record: activeTask, ...props }) => {
  React.useEffect(() => {
    props.fetchManagerAgents();
  }, []);

  const leadTaskId = getTaskObjId(activeTask);
  let isSaved = leadTaskId;
  let title = isSaved ? "Edit Lead Task" : "New Lead Task";
  const location = useLocation();
  const dispatch = useDispatch();
  let initialValue = {};
  if (activeTask?.lead_status) {
    initialValue = {
      value: activeTask?.lead_status,
      label: LEAD_STATUS_CHOICES_MAP[activeTask?.lead_status] || "",
    };
  }
  let [statusValue, setStatusValue] = React.useState(initialValue);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...activeTask,
        owner: isEmpty(activeTask) ? props.userId : activeTask.owner,
        due_date: isEmpty(activeTask) ? props.defaultDueDate : activeTask.due_date,
        type: activeTask?.task_obj?.type || activeTask.type,
        lead_status: activeTask?.lead_status,
        status: activeTask?.task_obj?.status || activeTask.status,
        created_by: activeTask?.record_creator || activeTask.created_by,
        last_edited_by_name: activeTask?.last_editor || activeTask?.last_edited_by_name,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const method = isEmpty(activeTask) ? props.createLeadTask : partial(props.editLeadTask, leadTaskId);

        values.lead_id = props.activeLeadId ? props.activeLeadId : activeTask?.task_obj?.lead_id;

        try {
          if (values.record_id) {
            await props.editLeadFromLeadTaskForm(values.lead_id, {
              phone: values.phone_number,
              other_phone: values.other_phone,
              email: values.email,
              notes: values.lead_notes,
            });
          }
          setTimeout(async () => {
            await method(values);
          }, 500);
          notifications.clearAll();
          notifications.success("Lead Task saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Task");
        }
        // Refresh tasks table - depends if we are in All Tasks or Lead Tasks
        if (props.activeTab === "tasks" || props.activeTab === "lead-tasks") {
          let type = TaskTab.ALL;
          let filterValues = props.recordsFilters;
          if (location.pathname.includes(URLS.LEAD_TASKS)) {
            type = TaskTab.LEAD;
            filterValues = props.leadRecordsFilters;
          }
          const fetch_action = FETCH_RECORDS_BY_TYPE_MAP[type];
          setTimeout(async () => {
            await props.fetchTasks({ ...filterValues }, fetch_action);
          }, 500);
        }
        dispatch(hideModal(LEAD_TASK_MODAL));
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        const lead_id = values.task_obj?.lead_id;
        return (
          <FormCollapsible onSubmit={handleSubmit} title={title}>
            {values.record_id ? (
              <Collapsible title="Lead Detail">
                <Form.Row>
                  <TextInput label="Phone" phone={true} name="phone_number" />
                  <TextInput label="Other Phone" phone={true} name="other_phone" />
                  <TextInput
                    label="Email"
                    name="email"
                    append={
                      values.email ? <SendLeadEmailFromTemplateIcon leadId={lead_id} mail={values.email} /> : null
                    }
                  />
                </Form.Row>
                <Form.Row>
                  <HyperLinkText
                    label="Record ID"
                    value={values.record_id || "---"}
                    url={lead_id ? `/leads/${lead_id}/overview/` : ""}
                    name="record_id"
                    inline={true}
                    disabled
                  />
                  <TextInput label="Record Name" name="record_name" disabled />
                  <Select
                    label="Lead Status"
                    name="lead_status"
                    defaultValue={statusValue}
                    options={leadStatusChoices}
                    onChange={async e => {
                      await props.editLeadFromLeadTaskForm(lead_id, { status: e.value });
                      handleSubmit();
                    }}
                  />
                </Form.Row>
                <Form.Row>
                  <EditorField label="Lead Notes" name="lead_notes" autoFocus={false} />
                </Form.Row>
              </Collapsible>
            ) : null}
            <Collapsible title="Task Detail">
              <Form.Row>
                <Select label="Type" name="type" placeholder="Select Type" options={TASK_TYPES} />
                <Select label="Task Status" name="status" placeholder="Select Status" options={TASK_STATUS} />
                <Select label="Owner" name="owner" placeholder="Select Owner" options={props.userChoices} />
                <DateInput label="Due Date" name="due_date" />
              </Form.Row>
              <Form.Row>
                <TextInput label="Task Creator" name="created_by" disabled />
                <DateInput label="Create Date" name="date_created" disabled />
                <TextInput label="Last Edited By" name="last_edited_by_name" disabled />
                <DateTimeInput label="Last Edit Date" name="last_edit_date" disabled />
              </Form.Row>
              <Form.Row>
                <EditorField label="Task Notes" name="notes" autoFocus={false} />
              </Form.Row>
            </Collapsible>
            <SubmitButton />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const userChoices = userChoicesSelector(state).getChoices({ sortByLabel: true });
  const defaultDueDate = addThreeBusinessDays().toISOString().split("T")[0];
  return {
    activeLeadId: activeLeadIdSelector(state),
    userChoices,
    defaultDueDate,
    userId: state.auth.user_id,
    activeTab: activeTabSelector(state),
    recordsFilters: storedRecordsFiltersSelector(state),
    leadRecordsFilters: storedLeadRecordsFiltersSelector(state),
  };
};

export default connect(mapStateToProps, {
  createLeadTask,
  editLeadTask,
  editLeadFromLeadTaskForm,
  fetchManagerAgents,
  fetchTasks: fetchRecords,
  showModal,
})(LeadTaskForm);
