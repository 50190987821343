import React from "react";

import { Card } from "react-bootstrap";
import { Tab, TabGroup } from "components/Tabs";

const TopHeaderTabs = ({ tabs = [], ...props }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: "4rem",
        left: "0rem",
        width: "100%",
        zIndex: 25,
      }}
    >
      <Card style={{ border: "1px solid black", borderRadius: "0px" }} />
      <h3 className={"header tertiary"}>
        <TabGroup
          style={{
            width: "100%",
          }}
        >
          {tabs.map((tab, index) => {
            return (
              <Tab key={index} link={tab.link}>
                {tab.label}
              </Tab>
            );
          })}
        </TabGroup>
      </h3>
    </div>
  );
};

export default TopHeaderTabs;
