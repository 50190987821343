import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { SEND_LEAD_EMAIL_MODAL } from "components/modals";
import SendLeadEmailForm from "./SendLeadEmailForm";
import { fetchEmailTemplates } from "../../../actions";

const SendLeadEmailModal = ({ mail, ...props }) => {
  React.useEffect(() => {
    props.fetchEmailTemplates();
  }, []);
  return (
    <FullPageModal modalType={SEND_LEAD_EMAIL_MODAL} title={`Send Lead Email Template`}>
      <SendLeadEmailForm mail={mail} />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, { fetchEmailTemplates })(SendLeadEmailModal);
