import React from "react";
import { connect } from "react-redux";

import { accountsSelector, activeAccountSelector, loadingAccountsSelector } from "reducers";
import { URLS } from "../../constants";
import { Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, TabGroup } from "../../components/Tabs";
import { fetchEngagementActivities, fetchPageAndSelectEntry, selectAccount, setShouldSave, showModal } from "actions";
import { isAdmin } from "permissions";
import { ButtonSaveChanges } from "../../components/buttons";
import { NavButton } from "../../components/TopHeaderButtons";
import { ENDPOINTS_AND_PAGE_TO_PAGE_SELECTOR_MAPPING } from "../../actions/pagination/constants";
import BackToButton from "../../components/buttons/BackToButton";
import usePaginationNavigation from "../../hooks/usePaginationNavigation";
import { ClipLoader } from "react-spinners";
import { getLastUrlSegment } from "utils";

const AccountTopHeader = ({ accountInfo, ...props }) => {
  const { id } = useParams();
  const path = `/accounts/${id}`;
  const history = useNavigate();

  const selectEntry = entry => {
    const lastSegment = getLastUrlSegment() || "information";
    history(`${URLS.ACCOUNTS}${entry.id}/${lastSegment}/`);
    props.selectAccount(entry.id);
    props.fetchEngagementActivities(entry.id);
  };

  const { onClickPreviousPage, onClickNextPage, onClickPreviousEntry, onClickNextEntry, previous, next } =
    usePaginationNavigation({
      entryType: "ACCOUNTS",
      entries: props.accounts,
      entryInfo: accountInfo,
      fetchPageAndSelectEntry: props.fetchPageAndSelectEntry,
      selectEntry,
      canPreviousPage: props.canPreviousPage,
      canNextPage: props.canNextPage,
      entriesSelector: accountsSelector,
    });

  let accountName = "";
  if (accountInfo) {
    if (accountInfo.type === 0) {
      if (accountInfo.last_name && accountInfo.first_name) {
        accountName = `${accountInfo.last_name}, ${accountInfo.first_name}`;
      }
    } else if (accountInfo.type === 1) {
      accountName = accountInfo.entity_name;
    }
  }

  React.useEffect(() => {
    if (accountName) {
      document.title = `LifeRoc | ${accountName} | Account`;
    }
  }, [accountName]);

  const PreviousAccountButton = () => (
    <NavButton
      direction="previous"
      type="account"
      disabled={!previous && !props.canPreviousPage}
      onClick={() => (previous ? onClickPreviousEntry() : onClickPreviousPage())}
    />
  );

  const NextAccountButton = () => (
    <NavButton
      direction="next"
      type="account"
      disabled={!next && !props.canNextPage}
      onClick={() => (next ? onClickNextEntry() : onClickNextPage())}
    />
  );

  return (
    <div
      style={{
        position: "fixed",
        top: "4rem",
        left: "0rem",
        width: "100%",
        zIndex: 25,
      }}
    >
      <Card style={{ border: "1px solid black", borderRadius: "0px" }}>
        <Card.Header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "black",
            padding: "0.75rem 1rem",
          }}
        >
          {/* Left: Account Name */}
          <h5 style={{ color: "white", margin: "5px 0 0 1rem", display: "flex", alignItems: "center", gap: "8px" }}>
            Account: {props.loading ? <ClipLoader size={22} color={"white"} /> : accountName}
          </h5>

          {/* Center: Previous and Next Buttons */}
          {(props.loading || previous || next) && (
            <div className={"prevNextButtonContainer"}>
              <PreviousAccountButton />
              <NextAccountButton />
            </div>
          )}

          {/* Right: Back and Save Buttons */}
          <div style={{ display: "flex", gap: "0.75rem" }}>
            <BackToButton entity="Accounts" url={URLS.ACCOUNTS} />;
            <ButtonSaveChanges saveFn={props.saveFn} />
          </div>
        </Card.Header>
      </Card>
      <h3 className={"header tertiary"}>
        <TabGroup
          style={{
            width: "100%",
          }}
        >
          <Tab link={`${path}/information/`}>Account Information</Tab>
          <Tab link={`${path}/cases/`}>Account Cases</Tab>
          {props.hasAdminPermission && <Tab link={`${path}/licensing/`}>Account Licensing & Contracting</Tab>}
        </TabGroup>
      </h3>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  let pageCount = ENDPOINTS_AND_PAGE_TO_PAGE_SELECTOR_MAPPING.ACCOUNTS.PAGES(state) || 0;
  let currentPage = ENDPOINTS_AND_PAGE_TO_PAGE_SELECTOR_MAPPING.ACCOUNTS?.CURRENT(state) || 0;
  let loading = loadingAccountsSelector(state);

  return {
    hasAdminPermission: isAdmin(state),
    accountInfo: activeAccountSelector(state),
    accounts: accountsSelector(state),
    canPreviousPage: !loading && currentPage > 1,
    canNextPage: !loading && currentPage < pageCount,
    loading,
  };
};

export default connect(mapStateToProps, {
  showModal,
  setShouldSave,
  selectAccount,
  fetchEngagementActivities,
  fetchPageAndSelectEntry,
})(AccountTopHeader);
