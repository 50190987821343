import React from "react";
import { connect } from "react-redux";

import { FaArrowLeft } from "react-icons/fa";
import { PERMISSIONS, URLS } from "../../../constants";
import { Card } from "react-bootstrap";
import { setShouldSave, showModal } from "actions";
import { activeMortalityAssessmentSelector } from "reducers";
import { Tab, TabGroup } from "../../../components/Tabs";
import { isAdmin } from "permissions";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonSaveChanges } from "components/buttons";
import BackToButton from "../../../components/buttons/BackToButton";

const MortalityAssessmentTopHeader = ({ mortalityAssessmentInfo, ...props }) => {
  const { id } = useParams();
  const path = `/riskx-assessments/${id}`;

  let parameterName = mortalityAssessmentInfo?.display_name || "";

  React.useEffect(() => {
    if (mortalityAssessmentInfo?.last_name) {
      let name = `${mortalityAssessmentInfo.last_name}, ${mortalityAssessmentInfo.first_name} | ${mortalityAssessmentInfo.case_id} | DATE`;
      document.title = `LifeRoc | ${name} | Assessment`;
    }
  }, [mortalityAssessmentInfo]);

  return (
    <div
      style={{
        position: "fixed",
        top: "4rem",
        left: "0rem",
        width: "100%",
        zIndex: 25,
      }}
    >
      <Card style={{ border: "1px solid black", borderRadius: "0px" }}>
        <Card.Header
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            backgroundColor: "black",
          }}
        >
          <h5
            className="section-title navbar-title"
            style={{
              color: "white",
              alignItems: "center",
              marginBottom: 0,
              lineHeight: 2,
            }}
          >
            {parameterName}
          </h5>
          <div style={{ display: "flex" }}>
            <BackToButton entity="Assessments" url={URLS.MORTALITY_ASSESSMENTS} />;
            <ButtonSaveChanges saveFn={props.saveFn} />
          </div>
        </Card.Header>
      </Card>
      <h3 className={"header tertiary"}>
        <TabGroup
          style={{
            width: "100%",
          }}
        >
          <Tab link={`${path}/summary/`}>Assessment Summary</Tab>
          <Tab link={`${path}/curve/`}>Custom Curves</Tab>
          <Tab link={`${path}/viewer/`}>Records Viewer</Tab>
        </TabGroup>
      </h3>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    hasAdminPermission: isAdmin(state),
    mortalityAssessmentInfo: activeMortalityAssessmentSelector(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
  setShouldSave,
})(MortalityAssessmentTopHeader);
