import React from "react";
import { connect } from "react-redux";

import { fetchAccountsListChoices, fetchFunder, fetchLifeSettlementChoices } from "actions";
import { Outlet } from "react-router-dom";
import FunderDetailTopHeader from "./FunderDetailTopHeader";
import withRouter from "../withRouter";
import { compose } from "redux";

/* Data component: fetch relevant data for the current funder */
const FunderSetup = ({ funderId, ...props }) => {
  const fetchData = () => {
    props.fetchFunder(funderId);
    props.fetchLifeSettlementChoices();
    props.fetchAccountsListChoices();
  };

  React.useEffect(() => {
    console.log("FunderSetup useEffect");
    fetchData();
  }, []);

  return (
    <div>
      <FunderDetailTopHeader />
      <Outlet />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const funderId = ownProps.params.id;

  return {
    funderId,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchFunder,
    fetchLifeSettlementChoices,
    fetchAccountsListChoices,
  }),
)(FunderSetup);
