import {
  FETCH_LIFE_SETTLEMENT_CASES,
  DELETE_LIFE_SETTLEMENT_CASE,
  LOADING,
  FETCH_CASE_PDF,
  STORE_MAILING_DATA,
  SHOULD_SAVE,
  CLEAN_LIFE_SETTLEMENT_SEARCH_DATA,
  CLEAR_CASES_DATA,
} from "actions/types";
import { arrayToMapIndexedById } from "reducers/utils";

export const defaultState = {
  cases: new Map([]),
  shouldSave: false,
  selectedCase: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CLEAR_CASES_DATA:
      return defaultState;
    case FETCH_LIFE_SETTLEMENT_CASES:
      return {
        ...state,
        cases: arrayToMapIndexedById(action.payload),
      };
    case CLEAN_LIFE_SETTLEMENT_SEARCH_DATA:
      return {
        ...state,
      };
    case FETCH_CASE_PDF:
      return {
        ...state,
        casePdf: action.payload,
      };
    case STORE_MAILING_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case DELETE_LIFE_SETTLEMENT_CASE: {
      const newCases = new Map(state.cases);
      newCases.delete(action.payload.id);

      return {
        ...state,
        cases: newCases,
      };
    }

    case LOADING:
      if (action.payload.cases) {
        return {
          ...state,
          cases: arrayToMapIndexedById([]),
        };
      } else {
        return state;
      }
    case SHOULD_SAVE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export const casesSelector = state => {
  if (state.cases) {
    try {
      return Array.from(state.cases.values());
    } catch (e) {
      return [];
    }
  } else {
    return [];
  }
};

export const shouldSaveSelector = state => state.shouldSave;
