import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { isEmpty } from "lodash";

import * as notifications from "../../../../notifications";
import { EditorField, FormCollapsible, DateInput, StateSelect, TextInput, Select } from "components/forms";
import { createAccountCarrierAppointment, editAccountCarrierAppointment } from "actions";
import {
  activeAccountIdSelector,
  activeCarrierAppointmentSelector,
  generalAgenciesSelector,
  managementChoicesSelector,
} from "reducers";
import { partial, prepareModalMultipleSelect } from "utils";
import WideSaveButton from "components/buttons/WideSaveButton";
import { appointmentStatusChoices, linesOfBusinessChoices } from "constants.js";
import { usStatesChoices } from "options";
import { parseDateTimeAsDate } from "../../utils";

const CarrierAppointmentForm = ({ activeCarrierAppointment, generalAgencies, loading, ...props }) => {
  let {
    carrier_id,
    code_number,
    start_date,
    end_date,
    states,
    lines_of_business,
    appointment_status,
    general_agency,
    notes,
  } = activeCarrierAppointment;

  let [commaSeparatedStates, setCommaSeparatedStates] = React.useState();
  const handleStatesChange = selectedOptions => {
    if (selectedOptions) {
      setCommaSeparatedStates(selectedOptions.map(o => o.value).join(","));
    } else {
      setCommaSeparatedStates("");
    }
  };

  let [linesOfBusiness, setLinesOfBusiness] = React.useState();
  const handleLinesOfBusinessChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedValues = selectedOptions.map(o => o.value).join(",");
      setLinesOfBusiness(commaSeparatedValues);
    } else {
      setLinesOfBusiness(undefined);
    }
  };

  let states_initial = prepareModalMultipleSelect("states", usStatesChoices.getChoices(), activeCarrierAppointment);
  let lines_of_business_initial = prepareModalMultipleSelect(
    "lines_of_business",
    linesOfBusinessChoices,
    activeCarrierAppointment,
  );

  return (
    <Formik
      enableReinitialize={false}
      initialValues={{
        carrier_id,
        code_number,
        start_date: parseDateTimeAsDate(start_date),
        end_date: parseDateTimeAsDate(end_date),
        states,
        general_agency,
        lines_of_business,
        appointment_status,
        notes,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const method = !(activeCarrierAppointment && activeCarrierAppointment.id)
          ? props.createAccountCarrierAppointment
          : partial(props.editAccountCarrierAppointment, activeCarrierAppointment.id);

        values.account = props.activeAccountId;
        values.states = commaSeparatedStates;
        values.lines_of_business = linesOfBusiness;

        try {
          await method(values);
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Carrier Appointment");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="Carrier Appointment Detail">
            <Form.Row>
              <Select
                label="Carrier"
                name="carrier_id"
                placeholder="Select Carrier"
                options={props.carrierChoices?.getChoices() || []}
              />
              <TextInput label="Carrier Code Number" name="code_number" />
              <DateInput label="Start Date" name="start_date" />
              <DateInput label="End Date" name="end_date" />
            </Form.Row>
            <Form.Row>
              <StateSelect
                label="States"
                name="states"
                placeholder="Select States"
                isMulti={true}
                onChangeCallback={handleStatesChange}
                defaultValue={states_initial}
              />
              <Select
                label="Lines of Business"
                name="lines_of_business"
                placeholder="Select Lines of Business"
                options={linesOfBusinessChoices}
                isMulti={true}
                onChangeCallback={handleLinesOfBusinessChange}
                defaultValue={lines_of_business_initial}
              />
              <Select label="Appointment Status" name="appointment_status" options={appointmentStatusChoices} />
              <Select label="General Agency" name="general_agency" options={generalAgencies} />
            </Form.Row>
            <Form.Row>
              <EditorField label="Notes" name="notes" />
            </Form.Row>
            <WideSaveButton submitRef={props.submitRef} />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);

  return {
    generalAgencies: generalAgenciesSelector(state),
    activeAccountId: activeAccountIdSelector(state),
    activeCarrierAppointment: activeCarrierAppointmentSelector(state) || {},
    carrierChoices: choices.carriers,
  };
};

export default connect(mapStateToProps, {
  createAccountCarrierAppointment,
  editAccountCarrierAppointment,
})(CarrierAppointmentForm);
