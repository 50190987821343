import {
  SELECT_LEAD,
  FETCH_LEAD,
  CLEAR_SELECTED_LEAD,
  FETCH_LEADS,
  CREATE_LEAD,
  EDIT_LEAD,
  DELETE_LEAD,
  EDIT_LEAD_TASK,
  SELECT_LEAD_TASK,
  FETCH_LEAD_TASKS,
  FETCH_LEAD_TASK,
  LOADING,
  SELECT_LEAD_LIST,
  DESELECT_LEAD_LIST,
  SELECT_SINGLE_LEAD,
} from "actions/types";
import { arrayToMapIndexedById, arrayToObjectIndexedById } from "../../utils";

export const defaultState = {
  filters: {},
  leads: new Map([]),
  leadTasks: [],
  activeLeadId: null,
  activeTask: {},
  selectedLeads: [],
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case DELETE_LEAD: {
      const newLeads = new Map(state.leads);
      newLeads.delete(action.payload);

      return {
        ...state,
        leads: newLeads,
      };
    }

    case CLEAR_SELECTED_LEAD:
      return { ...state, activeLeadId: defaultState.activeLeadId };

    case SELECT_LEAD:
      return { ...state, activeLeadId: action.payload };

    case FETCH_LEAD: {
      return {
        ...state,
        leads: new Map(state.leads).set(action.payload.id, action.payload),
      };
    }

    case FETCH_LEADS:
      return { ...state, leads: arrayToMapIndexedById(action.payload) };

    case FETCH_LEAD_TASK: {
      return {
        ...state,
        leadTasks: {
          ...state.leadTasks,
          [action.payload.id]: {
            ...state.leadTasks[action.payload.id],
            ...action.payload,
          },
        },
      };
    }

    case FETCH_LEAD_TASKS:
      return { ...state, leadTasks: arrayToObjectIndexedById(action.payload) };

    case EDIT_LEAD:
    case CREATE_LEAD: {
      return {
        ...state,
        activeLeadId: action.payload.id,
        leads: new Map(state.leads).set(action.payload.id, action.payload),
      };
    }

    case EDIT_LEAD_TASK:
    case SELECT_LEAD_TASK:
      return { ...state, activeTask: action.payload };

    case SELECT_LEAD_LIST:
      return {
        ...state,
        selectedLeads: [...state.selectedLeads, action.payload.id],
      };

    case SELECT_SINGLE_LEAD:
      return {
        ...state,
        selectedLeads: [action.payload.id],
      };

    case DESELECT_LEAD_LIST:
      return {
        ...state,
        selectedLeads: state.selectedLeads.filter(item => item !== action.payload.id),
      };

    case LOADING:
      if (action.payload.leads) {
        return {
          ...state,
          leads: arrayToObjectIndexedById([]),
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};

export const activeLeadSelector = ({ leads, activeLeadId }) => {
  if (leads) {
    try {
      return leads.get(Number(activeLeadId));
    } catch (e) {
      return {};
    }
  } else {
    return {};
  }
};

export const activeLeadIdSelector = state => {
  return state.activeLeadId;
};

export const leadsSelector = state => {
  if (state.leads) {
    try {
      return Array.from(state.leads.values());
    } catch (e) {
      return [];
    }
  } else {
    return [];
  }
};

export const activeLeadTaskSelector = state => {
  return state.activeTask;
};

export const leadTasksSelector = state => Object.values(state.leadTasks);

export const selectedLeadsSelector = state => state.selectedLeads;
