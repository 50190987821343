import React from "react";
import { connect, useDispatch } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { SEND_MANAGER_EMAIL_MODAL } from "components/modals";
import { deleteAuctionActivityFile, hideModal, sendEmailNotification } from "actions";
import { Formik } from "formik";
import { EditorField, FormCollapsible, SubmitButton, TextInput } from "components/forms";
import { Form } from "react-bootstrap";
import { activeCaseSelector, insuredListSelector, loginInfoSelector, managementChoicesSelector } from "reducers";
import { findObjectByPropertyValue, moneyDisplayShort } from "../../../../utils";
import * as notifications from "../../../../notifications";

const SendManagerEmailModal = ({ ...props }) => {
  if (!props.hierarchy) {
    return;
  }

  const findToRecipient = (idValue, name) => {
    let managerData = findObjectByPropertyValue(props.salesRepChoices, "value", idValue);
    if (managerData && managerData.email) {
      return `${name} <${managerData.email}>`;
    }
  };

  let _to = [];
  let insured_names = "";
  if (props.insuredInfo.length === 1) {
    insured_names = `${props.insuredInfo[0].last_name}, ${props.insuredInfo[0].first_name}`;
  } else if (props.insuredInfo.length === 2) {
    insured_names = `${props.insuredInfo[0].last_name}, ${props.insuredInfo[0].first_name} & ${props.insuredInfo[1].last_name}, ${props.insuredInfo[1].first_name}`;
  }
  let face_amount = moneyDisplayShort(props.caseInfo.face_amount);
  let carrier_name = props.caseInfo.carrier_name;
  let subject = `Case Auction Notification: ${props.caseInfo.submission_id} | ${insured_names} | ${face_amount} | ${carrier_name}`;
  let body = `Dear Manager, please be advised this has now been sent to Auction. We will keep you posted as we get feedback.<br><br>Thank you,<br><br>${props.user_name}`;
  if (props.hierarchy.sales_representative && props.hierarchy.sales_representative_name !== "None / House") {
    let sales_rep_email = findToRecipient(
      props.hierarchy.sales_representative,
      props.hierarchy.sales_representative_name,
    );
    if (sales_rep_email) {
      _to.push(sales_rep_email);
    }
  }

  if (props.hierarchy.national_account_manager && props.hierarchy.national_account_manager_name !== "None / House") {
    let national_account_manager_email = findToRecipient(
      props.hierarchy.national_account_manager,
      props.hierarchy.national_account_manager_name,
    );
    if (national_account_manager_email) {
      _to.push(national_account_manager_email);
    }
  }

  _to = _to.join("; ");
  const dispatch = useDispatch();

  return (
    <FullPageModal modalType={SEND_MANAGER_EMAIL_MODAL} title={`Send Manager Email`}>
      <Formik
        enableReinitialize
        initialValues={{
          _to,
          subject,
          body,
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          const response = await props.sendEmailNotification(values);
          dispatch(hideModal(SEND_MANAGER_EMAIL_MODAL));
          if (response && response.status === 500) {
            notifications.error("Error sending email");
          } else {
            notifications.success("Email sent");
            dispatch(hideModal(SEND_MANAGER_EMAIL_MODAL));
          }
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, values, setFieldValue }) => {
          return (
            <>
              <FormCollapsible onSubmit={handleSubmit} title="Send Email">
                <Form.Row>
                  <TextInput label="To" name="_to" as="textarea" rows={2} />
                </Form.Row>
                <Form.Row>
                  <TextInput label="CC" name="cc" placeholder="Enter CC Email(s)" />
                  <TextInput label="BCC" name="bcc" placeholder="Enter BCC Email(s)" />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Subject" name="subject" />
                </Form.Row>
                <Form.Row>
                  <EditorField label="Email Template" name="body" />
                </Form.Row>
                <SubmitButton defaultText={"Send"} />
              </FormCollapsible>
            </>
          );
        }}
      </Formik>
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  const caseInfo = activeCaseSelector(state);
  const choices = managementChoicesSelector(state);
  const { user_name } = loginInfoSelector(state);
  return {
    caseInfo,
    insuredInfo: insuredListSelector(state),
    salesRepChoices: choices.case_sales_representatives.getChoices(),
    user_name,
    hierarchy: caseInfo.hierarchy,
  };
};

export default connect(mapStateToProps, {
  deleteAuctionActivityFile,
  sendEmailNotification,
})(SendManagerEmailModal);
