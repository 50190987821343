import { connect } from "react-redux";

import {
  activeCaseSelector,
  marketAuctionActivitiesSelector,
  fundersForAuctionSelector,
  managementChoicesSelector,
} from "reducers";
import { parseFloatFromString } from "../../../../utils";

const WinningMarketBidderValue = props => {
  const marketActivities = props.auction_activities.filter(activity => activity.type === "B" || activity.type === "BC");
  if (marketActivities.length > 0) {
    const marketActivitiesWithOffer = marketActivities.filter(activity => activity.provider && activity.gross_offer);
    const highestGrossOfferActivity = marketActivitiesWithOffer.reduce(
      (a, b) => (parseFloatFromString(a.gross_offer) > parseFloatFromString(b.gross_offer) ? a : b),
      { gross_offer: 0 },
    );
    if (!highestGrossOfferActivity) {
      return "";
    }
    let providerObj = props.providerChoices?.getLabel(highestGrossOfferActivity.provider);
    if (providerObj) {
      return props.providerChoices?.getLabel(highestGrossOfferActivity.provider);
    } else {
      return "";
    }
  }
  return "";
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  return {
    caseInfo: activeCaseSelector(state),
    providerChoices: choices.case_providers,
    auctionFunders: fundersForAuctionSelector(state),
    auction_activities: marketAuctionActivitiesSelector(state),
  };
};

export default connect(mapStateToProps)(WinningMarketBidderValue);
