import {
  activeAgencySelector,
  activeTabSelector,
  pageSizeSelector,
  sortBySelector,
  tabBasedRecordsSelector,
} from "../../reducers";
import { LOADING, SET_ACTIVE_TAB, SET_PAGE_SIZE, SORT_BY } from "../types";
import advisorhq from "../../apis/advisorhq";
import { getKeyByValue, sortByObj, sortByParam } from "../../utils";
import {
  ENDPOINTS_AND_PAGE_TO_PAGE_SELECTOR_MAPPING,
  ENDPOINTS_TO_ACTION_TYPES_MAPPING,
  ENDPOINTS_TO_ACTIVE_SECTIONS_MAPPING,
  ENDPOINTS_TO_FILTERS_SELECTOR_MAPPING,
  ENDPOINTS_TO_LOADING_VALUE_MAPPING,
  ENDPOINTS_TO_PAGINATION_ACTION_TYPES_MAPPING,
  ENDPOINTS_TO_URL_MAPPING,
} from "./constants";

export const parseActiveSectionToEndpoint = activeSection => {
  return getKeyByValue(ENDPOINTS_TO_ACTIVE_SECTIONS_MAPPING, activeSection);
};

const getPageNumber = (state, endpoint, page) => {
  if (typeof page === "number") return page;
  const selector = ENDPOINTS_AND_PAGE_TO_PAGE_SELECTOR_MAPPING[endpoint][page];
  const match = selector(state).match(/page=([^&]+)/);
  return match ? match[1] : 1;
};

const createLoadingAction = (endpoint, value) => {
  const loading_key = ENDPOINTS_TO_LOADING_VALUE_MAPPING[endpoint];
  const payload = {};
  payload[loading_key] = value;
  return { type: LOADING, payload };
};

const storePaginationData = (endpoint, paginateData) => {
  const action_type = ENDPOINTS_TO_PAGINATION_ACTION_TYPES_MAPPING[endpoint];
  return { type: action_type, payload: paginateData };
};

const storePaginatedRequestResults = (endpoint, results) => {
  const action_type = ENDPOINTS_TO_ACTION_TYPES_MAPPING[endpoint];
  return { type: action_type, payload: results };
};

const getPaginatedRequestFilters = (state, endpoint) => {
  const PaginatedRequestFiltersSelector = ENDPOINTS_TO_FILTERS_SELECTOR_MAPPING[endpoint];
  return PaginatedRequestFiltersSelector(state);
};

const setLoading = url => createLoadingAction(url, true);

const unsetLoading = url => createLoadingAction(url, false);

const getPaginatedResponseConfig = (state, pageNumber, filters, size = null) => {
  const activeAgency = activeAgencySelector(state);
  const sort_by = sortBySelector(state);
  const pageSize = pageSizeSelector(state);
  return {
    agency: activeAgency.id,
    page: pageNumber,
    page_size: size || pageSize,
    sort_by: sortByParam(sort_by),
    ...filters,
  };
};

export const fetchPage =
  (endpoint, page, size = null) =>
  async (dispatch, getState) => {
    // page is "previous", "next", or specific page ej: 5
    // size is optional, passed when changing page sizes
    const url = ENDPOINTS_TO_URL_MAPPING[endpoint];
    const state = getState();
    dispatch(setLoading(endpoint));
    if (size) dispatch({ type: SET_PAGE_SIZE, payload: size });
    const pageNumber = getPageNumber(state, endpoint, page);
    const filters = getPaginatedRequestFilters(state, endpoint);
    const config = getPaginatedResponseConfig(state, pageNumber, filters, size);
    const response = await advisorhq.get(url, { params: config });
    let { results, ...paginateData } = response.data;
    paginateData = { ...paginateData, page: pageNumber };
    dispatch(storePaginationData(endpoint, paginateData));
    dispatch(storePaginatedRequestResults(endpoint, results));
    dispatch(unsetLoading(endpoint));
  };

export const fetchPageAndSelectTask = (endpoint, page, selectFn) => async (dispatch, getState) => {
  await dispatch(fetchPage(endpoint, page));
  let entry;
  if (page === "NEXT") {
    entry = await tabBasedRecordsSelector(getState())[0];
  } else if (page === "PREVIOUS") {
    const records = await tabBasedRecordsSelector(getState());
    entry = records[records.length - 1];
  }
  selectFn(entry);
};

export const fetchPageAndSelectEntry = (endpoint, page, selectFn, selectorFn) => async (dispatch, getState) => {
  await dispatch(fetchPage(endpoint, page));
  let entry;
  if (page === "NEXT") {
    entry = await selectorFn(getState())[0];
  } else if (page === "PREVIOUS") {
    const records = await selectorFn(getState());
    entry = records[records.length - 1];
  }
  selectFn(entry);
};

export const applyOrderBy = inc_sort_by => async (dispatch, getState) => {
  const tab = activeTabSelector(getState());
  const sortBy = sortBySelector(getState());
  const endpoint = parseActiveSectionToEndpoint(tab);
  dispatch({ type: SORT_BY, payload: sortByObj(inc_sort_by, sortBy, tab) });
  dispatch(fetchPage(endpoint, 1));
};

export const setActiveTab = activeTab => async (dispatch, getState) => {
  const sortMapping = {
    cases: { id: "submission_date", desc: true },
    lifeexpectancy: { id: "certificate_date", desc: false },
    leads: { id: "system_id", desc: true },
    parameters: { id: "code", desc: false },
    accounts: { id: "last_edit_date", desc: true },
    tasks: { id: "due_date", desc: false },
    "account-tasks": { id: "due_date", desc: false },
    "case-tasks": { id: "due_date", desc: false },
    "lead-tasks": { id: "due_date", desc: false },
    "case-requirement-tasks": { id: "due_date", desc: false },
  };

  if (sortMapping[activeTab]) {
    dispatch({ type: SORT_BY, payload: sortMapping[activeTab] });
  }

  dispatch({ type: SET_ACTIVE_TAB, payload: activeTab });
};
