import React from "react";
import { connect } from "react-redux";
import { useNavigate, Outlet } from "react-router-dom";

import * as actions from "actions";
import { URLS } from "../../constants";
import { activeAgencySelector, auctionIdSelector, activeCaseSelector, auctionAccessEnabledSelector } from "reducers";
import withRouter from "../withRouter";
import { compose } from "redux";
import CaseTopHeader from "./detail/CaseTopHeader";

/* Data component: fetch relevant data for the current life settlement case.
 *
 * Instead of fetching the data in each of the case related pages, we create
 * a component to do it, and route it in a common url for all management pages.
 */
const CaseSetup = ({ caseId, caseAgencyId, activeAgencyId, auctionId, ...props }) => {
  const history = useNavigate();
  const fetchData = () => {
    props.selectLifeSettlementCase(caseId);
    props.fetchLifeSettlementChoices();
    props.fetchNonPaginatedLifeExpectancies();
    props.fetchManagerAgents();
    props.fetchEmailTemplates();
    props.fetchAccountsListChoices();
  };

  React.useEffect(() => {
    props.cleanLifeSettlementCaseData();
    props.fetchLifeSettlementCase(caseId);
    if (props.auctionAccessEnabled) {
      props.fetchAuction(caseId);
    }
  }, [caseId]);

  React.useEffect(() => {
    if (caseAgencyId) {
      if (caseAgencyId === activeAgencyId) {
        fetchData();
      } else {
        history(URLS.CASES);
      }
    }
  }, [caseId, activeAgencyId, caseAgencyId]);

  return (
    <div>
      <CaseTopHeader />
      <Outlet />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const caseId = ownProps.params.id;
  const activeAgencyId = activeAgencySelector(state).id;
  const auctionAccessEnabled = auctionAccessEnabledSelector(state);
  const caseInfo = activeCaseSelector(state) || {};
  const auctionId = auctionIdSelector(state);

  return {
    caseId,
    activeAgencyId,
    auctionAccessEnabled,
    caseAgencyId: caseInfo.agency,
    auctionId,
  };
};

export default compose(withRouter, connect(mapStateToProps, actions))(CaseSetup);
