import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import {
  BooleanSelect,
  EditorField,
  DateInput,
  FormCollapsible,
  Select,
  StateSelect,
  SubmitButton,
  TextInput,
  NumericInput,
} from "components/forms";
import { activeCaseSelector, activeProductSelector, managementChoicesSelector, shouldSaveSelector } from "reducers";
import { isEmpty } from "lodash";
import { partial } from "utils";
import * as notifications from "notifications";
import { createProduct, editProduct, fetchPolicy, unsetShouldSave } from "actions";
import { Form } from "react-bootstrap";
import { usStatesChoices } from "options";
import { policyTypeChoices, productRateClassChoices, productTypeChoices, tableRateChoices } from "../../constants";

const ProductForm = ({ product, ...props }) => {
  if (!product) {
    // Still didn't fetch data
    return null;
  }

  let {
    product_id,
    carrier_id,
    product_type,
    number_of_insured_lives,
    form_number,
    name,
    issue_states,
    issue_date_start,
    issue_date_end,
    policy_type,
    max_coi_age,
    max_db_age,
    notes,
    product_template_id,
    related_cases,
    age_basis,
    audited,
    rate_class,
    table_rating,
  } = product;

  let issue_states_initial = [];
  let stateChoices = usStatesChoices.getChoices();
  if (issue_states) {
    let issue_states_list = issue_states.split(",");
    for (let state_code of issue_states_list) {
      let stateData = stateChoices.find(e => e.value === state_code);
      issue_states_initial.push({ value: stateData.value, label: stateData.label });
    }
  }

  const submitRef = React.useRef();

  React.useEffect(() => {
    if (props.shouldSave) {
      submitRef.current.click();
    }
  }, [props.shouldSave]);

  let [commaSeparatedStates, setCommaSeparatedStates] = React.useState();

  const handleStatusChange = selectedOptions => {
    if (selectedOptions) {
      setCommaSeparatedStates(selectedOptions.map(o => o.value).join(","));
    } else {
      setCommaSeparatedStates(undefined);
    }
  };

  // If the initialValue has no comma, it locks for changes.
  // Blank it so the defaultValue takes preference
  if (",".indexOf(issue_states) === -1) {
    issue_states = "";
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        product_id,
        carrier_id,
        product_type,
        number_of_insured_lives,
        form_number,
        name,
        issue_states,
        issue_date_start,
        issue_date_end,
        policy_type,
        max_coi_age,
        max_db_age,
        notes,
        product_template_id,
        related_cases,
        age_basis,
        audited,
        rate_class,
        table_rating,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const method =
          isEmpty(product) || product.id === undefined ? props.createProduct : partial(props.editProduct, product.id);

        try {
          values.product_template_id = values.product_template_id || null;
          values.issue_states = commaSeparatedStates;
          await method(values);
          props.unsetShouldSave();
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Product");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <>
            <FormCollapsible onSubmit={handleSubmit} title="Product">
              <Form.Row>
                <TextInput label="Product ID" name="product_id" disabled />
                <Select
                  label="Carrier"
                  name="carrier_id"
                  placeholder="Select Carrier"
                  options={props.carrierChoices?.getChoices() || []}
                  emptyValue={null}
                />
                <Select
                  label="Product Type"
                  name="product_type"
                  placeholder="Select Product Type"
                  options={productTypeChoices}
                />
                <Select
                  label="Insured Lives"
                  name="number_of_insured_lives"
                  placeholder="Select Insured Lives"
                  emptyValue={null}
                  options={[
                    { value: 1, label: "Single" },
                    { value: 2, label: "Joint Survivor" },
                  ]}
                />
              </Form.Row>
              <Form.Row>
                <TextInput label="Form Number" name="form_number" />
                <TextInput label="Product Name" name="name" />
                <StateSelect
                  label="Issue States"
                  name="issue_states"
                  placeholder="Select States"
                  isMulti={true}
                  onChange={handleStatusChange}
                  defaultValue={issue_states_initial}
                />
                <Select
                  label="Policy Type"
                  name="policy_type"
                  placeholder="Select Policy Type"
                  options={policyTypeChoices}
                />
              </Form.Row>
              <Form.Row>
                <DateInput label="Issue Date Start" name="issue_date_start" />
                <DateInput label="Issue Date End" name="issue_date_end" />
                <Select
                  label="Age Basis"
                  name="age_basis"
                  placeholder="Select Age Basis"
                  options={[
                    { value: "NEAR", label: "Age Nearest" },
                    { value: "LAST", label: "Age Last" },
                  ]}
                />
                <Select
                  label="Rate Class"
                  name="rate_class"
                  placeholder="Select Rate Class"
                  options={productRateClassChoices}
                  md={3}
                />
              </Form.Row>
              <Form.Row>
                <NumericInput label="Max CoI Age" name="max_coi_age" />
                <NumericInput label="Max DB Age" name="max_db_age" />
                <BooleanSelect label="Product Audited" name="audited" />
                <Select
                  label="Table Rating"
                  name="table_rating"
                  placeholder="Select Table Rating"
                  options={tableRateChoices}
                  md={3}
                />
              </Form.Row>
              <Form.Row>
                <EditorField label="Notes" name="notes" />
              </Form.Row>
              <SubmitButton innerRef={submitRef} style={{ display: "none" }} />
            </FormCollapsible>
          </>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const caseInfo = activeCaseSelector(state);
  const choices = managementChoicesSelector(state);
  const currentProduct = activeProductSelector(state);

  return {
    shouldSave: shouldSaveSelector(state),
    product: currentProduct,
    carrierChoices: choices.carriers,
    activePolicyId: caseInfo && caseInfo.policy,
  };
};

export default connect(mapStateToProps, {
  createProduct,
  editProduct,
  fetchPolicy,
  unsetShouldSave,
})(ProductForm);
