import React from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const NavButton = ({ direction, type, disabled, onClick }) => {
  const isNext = direction === "next";
  const icon = isNext ? <FaArrowRight /> : <FaArrowLeft />;
  const labelFull = isNext ? "Next" : "Previous";
  const label = isNext ? "Next" : "Prev.";
  let tooltipText = "";
  if (!disabled) {
    tooltipText = `View ${labelFull.toLowerCase()} ${type} (or press ${isNext ? "➡ Right" : "⬅ Left"} Arrow)`;
  }

  return (
    <OverlayTrigger placement="bottom" delay={{ show: 300, hide: 200 }} overlay={<Tooltip>{tooltipText}</Tooltip>}>
      <span>
        <PrevNextButton disabled={disabled} onClick={onClick}>
          {!isNext && icon} {label} {isNext && icon}
        </PrevNextButton>
      </span>
    </OverlayTrigger>
  );
};

export const PrevNextButton = ({ onClick, children, disabled }) => (
  <button
    className="btn btn--secondary navbar-button"
    onClick={onClick}
    disabled={disabled}
    style={{ flex: 1, textAlign: "center", minWidth: "100px", height: "100%" }}
  >
    {children}
  </button>
);
