import React from "react";
import { connect } from "react-redux";

import CaseInfo from "./CaseInfo";
import {
  activeCaseSelector,
  auctionAccessEnabledSelector,
  auctionIdSelector,
  loadingSelector,
  selectedFundersSelector,
} from "../../../reducers";
import CaseTopHeaderNavbar from "./CaseTopHeaderNavbar";
import { useParams } from "react-router-dom";

const CaseTopHeader = props => {
  const caseOverviewFormSubmitRef = React.useRef();
  const closingDetailFormSubmitRef = React.useRef();
  const { id } = useParams();
  const managementPath = `/cases/${id}`;

  if (props.loading.error404) {
    return <>{"Case Not Found"}</>;
  } else {
    return (
      <div>
        <CaseTopHeaderNavbar managementPath={managementPath} />
        <CaseInfo
          caseOverviewFormSubmitRef={caseOverviewFormSubmitRef}
          closingDetailFormSubmitRef={closingDetailFormSubmitRef}
        />
      </div>
    );
  }
};

const mapStateToProps = state => {
  return {
    caseInfo: activeCaseSelector(state),
    loading: loadingSelector(state),
    auctionAccessEnabled: auctionAccessEnabledSelector(state),
    selectedFunders: selectedFundersSelector(state),
    auctionId: auctionIdSelector(state),
  };
};

export default connect(mapStateToProps, {})(CaseTopHeader);
