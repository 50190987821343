import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { SEND_ACCOUNT_EMAIL_MODAL } from "components/modals";
import SendAccountEmailForm from "./SendAccountEmailForm";
import { fetchEmailTemplates } from "../../../actions";

const SendAccountEmailModal = ({ mail, ...props }) => {
  React.useEffect(() => {
    props.fetchEmailTemplates();
  }, []);
  return (
    <FullPageModal modalType={SEND_ACCOUNT_EMAIL_MODAL} title={`Send Account Email Template`}>
      <SendAccountEmailForm mail={mail} />
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, { fetchEmailTemplates })(SendAccountEmailModal);
