import React from "react";
import { connect } from "react-redux";
import { useDropzone } from "react-dropzone";

import { showModal } from "actions";
import { CONFIRM_FILE_REPLACE_MODAL, REDACT_MODAL } from "components/modals";
import File from "./File";
import * as notifications from "notifications.js";
import { FaUpload } from "react-icons/fa";

const RedactedFileDropZone = ({ uploadFile, currentFile, accept, activeCaseFile, ...props }) => {
  let message = props.message || "Drag and drop file or click to select a file";

  const onDropFile = React.useCallback(acceptedFiles => {
    const droppedFile = acceptedFiles[0];
    if (!droppedFile) {
      notifications.error(`Invalid file! Accepted formats: ${accept}`);
    }

    if (currentFile && currentFile !== "") {
      props.showModal(CONFIRM_FILE_REPLACE_MODAL, {
        onConfirm: () => uploadFile(droppedFile),
      });
    } else {
      if (droppedFile.size > 300 * 1024 * 1024) {
        notifications.error(`File size must be below 300 MB`);
      } else {
        uploadFile(droppedFile);
      }
    }
  });

  let options = {
    onDrop: onDropFile,
    maxFiles: 1,
  };
  if (accept) {
    options.accept = accept;
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone(options);

  const getClassNames = () => {
    let className = "dropzone";
    if (isDragActive) {
      className += " active";
    }
    return className;
  };

  const renderMainComponent = () => {
    return (
      <div {...getRootProps({ className: getClassNames() })}>
        <input {...getInputProps()} />
        <div>{message}</div>
      </div>
    );
  };

  let titleBoxStyle = {
    border: "1px solid #ced4da",
    padding: "10px",
    height: "91px",
    width: "150px",
    display: "flex",
    alignItems: "center",
    fontWeight: "bold",
  };

  let progressBoxStyle = {
    border: "1px solid #ced4da",
    padding: "10px",
    height: "91px",
    width: "250px",
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
  };

  let redactionBox = {
    border: "1px solid #ced4da",
    padding: "10px",
    height: "91px",
    width: "210px",
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
  };

  const CustomDropzone = ({ onDrop }) => {
    const { getRootProps, getInputProps } = useDropzone({ onDrop });

    const dropzoneStyle = {
      marginLeft: 20,
      background: "#2185d0",
      color: "#fff",
      padding: "10px 20px",
      display: "flex",
      alignItems: "center",
      borderRadius: "4px",
      cursor: "pointer",
      justifyContent: "center",
      width: "150px",
      height: "35px",
      fontWeight: "500",
    };

    return (
      <div>
        <div {...getRootProps()} style={dropzoneStyle}>
          <FaUpload style={{ marginRight: "10px" }} />
          Upload File
          <input {...getInputProps()} />
        </div>
      </div>
    );
  };

  const renderRedactionOptions = type => {
    return (
      <div style={redactionBox}>
        <CustomDropzone
          onDrop={acceptedFiles => {
            const droppedFile = acceptedFiles[0];
            console.log("onDrop", type, props.uploadRedactedFile, droppedFile);
            let currentRedactedFile;
            if (type === "full") {
              currentRedactedFile = activeCaseFile && activeCaseFile.full_redacted_file;
            } else if (type === "partial") {
              currentRedactedFile = activeCaseFile && activeCaseFile.partial_redacted_file;
            }
            if (currentRedactedFile) {
              props.showModal(CONFIRM_FILE_REPLACE_MODAL, {
                onConfirm: () => props.uploadRedactedFile(droppedFile, type),
              });
            } else {
              props.uploadRedactedFile(droppedFile, type);
            }
          }}
        />
      </div>
    );
  };

  const renderPartialRedactedFile = () => {
    if (activeCaseFile) {
      if (activeCaseFile.partial_redacted_file) {
        return (
          <File
            file={activeCaseFile.partial_redacted_file}
            uploadFile={uploadFile}
            fileDownloadUrl={props.fileDownloadUrl + "?file_fieldname=partial_redacted_file"}
          />
        );
      } else {
        if (activeCaseFile.activeCaseFile) {
          let partialRedactedStatus = "Not Requested Yet";
          return <div style={progressBoxStyle}>{partialRedactedStatus}</div>;
        }
      }
    }
  };

  const renderFullRedactedFile = () => {
    if (activeCaseFile) {
      if (activeCaseFile.full_redacted_file) {
        return (
          <File
            file={activeCaseFile.full_redacted_file}
            uploadFile={uploadFile}
            fileDownloadUrl={props.fileDownloadUrl + "?file_fieldname=full_redacted_file"}
          />
        );
      } else {
        if (activeCaseFile.activeCaseFile) {
          let fullRedactedStatus = "Not Requested Yet";
          return <div style={progressBoxStyle}>{fullRedactedStatus}</div>;
        }
      }
    }
  };

  function renderFiles() {
    if (!activeCaseFile.file) {
      return <></>;
    }
    return (
      <>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <div style={titleBoxStyle}>
            <div>{"File:"}</div>
          </div>
          <div>
            <File
              file={currentFile}
              uploadFile={uploadFile}
              deleteFile={props.deleteFile}
              fileDownloadUrl={props.fileDownloadUrl}
              rotateFile={props.rotateFile}
            />
          </div>
          {activeCaseFile && (
            <div style={{ ...redactionBox, width: 160 }}>
              <button
                className="btn menu__trigger"
                style={{ marginLeft: 25 }}
                onClick={() => {
                  props.showModal(REDACT_MODAL, {});
                }}
              >
                Redact
              </button>
            </div>
          )}
        </div>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <div style={titleBoxStyle}>
            <div>{"Full Redacted:"}</div>
          </div>
          {renderFullRedactedFile()}
          {renderRedactionOptions("full")}
        </div>
        <div style={{ display: "flex", alignItems: "flex-start" }}>
          <div style={titleBoxStyle}>
            <div>{"Partial Redacted:"}</div>
          </div>
          {renderPartialRedactedFile()}
          {renderRedactionOptions("partial")}
        </div>
      </>
    );
  }

  return (
    <section className="container">
      {renderMainComponent()}
      {renderFiles()}
    </section>
  );
};

export default connect(null, { showModal })(RedactedFileDropZone);
