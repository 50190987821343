import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import {
  Select,
  TextInput,
  FormFilter,
  SubmitSearchButton,
  ClearFiltersButton,
  DateInput,
  BooleanSelect,
  GenderSelect,
  CurrencyInput,
} from "components/forms";
import {
  fetchLifeSettlementCases,
  fetchLifeSettlementChoices,
  fetchManagerAgents,
  fetchLifeExpectancyChoices,
  fetchAllLifeExpectancies,
  storeLesFiltersData,
  fetchAccountsListChoices,
} from "actions";
import { agentsChoicesSelector, managementChoicesSelector, lifeExpectancyChoicesSelector } from "reducers";
import { caseStatusChoices, vendorChoices } from "../../../constants";

const LifeExpectancyFilterForm = ({
  fetchLifeSettlementChoices,
  fetchLifeExpectancyChoices,
  fetchManagerAgents,
  ...props
}) => {
  React.useEffect(() => {
    fetchLifeSettlementChoices();
    fetchManagerAgents();
    fetchLifeExpectancyChoices();
    props.fetchAccountsListChoices();
  }, []);

  let [vendorValue, setVendorValue] = React.useState();

  const handleVendorChange = vendor => {
    vendor && vendor.value ? setVendorValue(vendor.value) : setVendorValue(vendor);
  };

  return (
    <Formik
      initialValues={{
        gender: "",
        vendor: "",
        clinical: "",
        tobacco_use: "",
        keyword_search_type: "OR",
        primary_impairment_search_type: "OR",
      }}
      onSubmit={async (values, { setSubmitting }) => {
        const vendorValue = values.vendor.value || "";
        props.storeLesFiltersData({ ...values, vendor: vendorValue });
        await props.fetchAllLifeExpectancies({
          ...values,
          vendor: vendorValue,
        });
        setSubmitting(false);
      }}
      onReset={async (values, { setSubmitting }) => {
        props.storeLesFiltersData({});
        await props.fetchAllLifeExpectancies();
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, resetForm, setFieldValue }) => {
        return (
          <FormFilter title={"Search Filters"} onSubmit={handleSubmit}>
            <Form.Row>
              <GenderSelect />
              <DateInput label="Insured DOB Low" name="insured_dob_low" />
              <DateInput label="Insured DOB High" name="insured_dob_high" />
              <BooleanSelect label="Tobacco Use" name="tobacco_use" />
            </Form.Row>
            <Form.Row>
              <TextInput label="Insured Age Low" name="insured_age_low" type="number" />
              <TextInput label="Insured Age High" name="insured_age_high" type="number" />
              <TextInput label="LE MM % Low" name="le_mm_low" type="number" />
              <TextInput label="LE MM % High" name="le_mm_high" type="number" />
            </Form.Row>
            <Form.Row>
              <TextInput label="LE Certificate Age Low" type="number" name="le_cert_age_low" />
              <TextInput label="LE Certificate Age High" type="number" name="le_cert_age_high" />
              <TextInput label="Mean LE Low" type="number" name="mean_le_low" />
              <TextInput label="Mean LE High" type="number" name="mean_le_high" />
            </Form.Row>
            <Form.Row>
              <DateInput label="LE Cert Date Low" name="le_cert_date_low" />
              <DateInput label="LE Cert Date High" name="le_cert_date_high" />
              <Select
                label="LE Provider"
                name="vendor"
                placeholder="Select Vendor"
                options={vendorChoices}
                onChange={e => {
                  handleVendorChange(e);
                  setFieldValue("vendor", e);
                }}
              />
              <Select
                label="Table"
                name="mortality_table_name"
                placeholder="Select Table"
                options={props.vendorChoices.mortality_table_name.getChoices()[vendorValue]}
              />
            </Form.Row>
            <Form.Row>
              <CurrencyInput label="Face Amount (Min)" name="face_amount_min" md={3} />
              <CurrencyInput label="Face Amount (Max)" name="face_amount_max" md={3} />
              <Select label="Status" name="status" placeholder="Select Status" options={caseStatusChoices} md={3} />
            </Form.Row>
            <Form.Row>
              <TextInput label="Min # of Insured LEs" name="min_num_insured_les" type="number" />
              <BooleanSelect label="Clinical" name="clinical" />
              <TextInput
                label="Text Keyword Search: "
                name="keyword_search"
                tooltip='Use "word1 word2" for exact search, * at the beginning as wildcard'
                md={3}
              />
              <Select
                label="Text Keyword Search Type"
                name="keyword_search_type"
                options={[
                  { value: "OR", label: "OR" },
                  { value: "AND", label: "AND" },
                ]}
                md={3}
              />
            </Form.Row>
            <Form.Row>
              <TextInput label="Insured Name" name="insured_names" md={3} />
              <TextInput label="LE Client" name="le_client" md={3} />
              <TextInput
                label="Primary Impairment Search"
                name="primary_impairment_search"
                tooltip='Use "word1 word2" for exact search, * at the beginning as wildcard'
                md={3}
              />
              <Select
                label="Primary Impairment Search Type"
                name="primary_impairment_search_type"
                options={[
                  { value: "OR", label: "OR" },
                  { value: "AND", label: "AND" },
                ]}
                md={3}
              />
            </Form.Row>
            <SubmitSearchButton />
            <ClearFiltersButton onClick={resetForm} />
          </FormFilter>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  const agentsChoices = agentsChoicesSelector(state);
  const lifeExpectancyChoices = lifeExpectancyChoicesSelector(state);

  return {
    carrierChoices: choices.carriers,
    providerChoices: choices.case_providers,
    accountChoices: choices.accounts,
    caseManagerChoices: agentsChoices.case_managers,
    pricingManagerChoices: agentsChoices.pricing_managers,
    auctionManagerChoices: agentsChoices.auction_managers,
    closingManagerChoices: agentsChoices.closing_managers,
    salesRepChoices: choices.case_sales_representatives,
    vendorChoices: lifeExpectancyChoices,
  };
};

export default connect(mapStateToProps, {
  fetchLifeSettlementCases,
  fetchLifeSettlementChoices,
  fetchManagerAgents,
  storeLesFiltersData,
  fetchLifeExpectancyChoices,
  fetchAllLifeExpectancies,
  fetchAccountsListChoices,
})(LifeExpectancyFilterForm);
