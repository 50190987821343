import React from "react";
import { connect } from "react-redux";
import { ButtonNext, ButtonPrevious } from "../../components/buttons";
import { fetchPageAndSelectTask } from "../../actions";
import { NavButton } from "../../components/TopHeaderButtons";

const TaskModalArrows = ({ prevEntry, nextEntry, onclick, setLoading, ...props }) => {
  const next = Boolean(nextEntry);
  const previous = Boolean(prevEntry);

  const onClickPreviousPage = () => {
    setLoading(true);
    props.fetchPageAndSelectTask(props.endpoint, "PREVIOUS", onclick);
    setTimeout(() => setLoading(false), 300);
  };

  const onClickNextPage = () => {
    setLoading(true);
    props.fetchPageAndSelectTask(props.endpoint, "NEXT", onclick);
    setTimeout(() => setLoading(false), 300);
  };

  const onClickPreviousEntry = () => {
    setLoading(true);
    onclick(prevEntry);
    setTimeout(() => setLoading(false), 300);
  };

  const onClickNextEntry = () => {
    setLoading(true);
    onclick(nextEntry);
    setTimeout(() => setLoading(false), 300);
  };

  const handleKeyDown = event => {
    // Ignore keydown if the focused element is inside a contenteditable div
    if (event.target.isContentEditable) return;

    if (event.key === "ArrowLeft") {
      if (previous) {
        onClickPreviousEntry();
      } else if (props.canPreviousPage) {
        onClickPreviousPage();
      }
    } else if (event.key === "ArrowRight") {
      if (next) {
        onClickNextEntry();
      } else if (props.canNextPage) {
        onClickNextPage();
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [prevEntry, nextEntry]); // Re-run effect when entries change

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: 0.5 + "rem",
      }}
    >
      <ButtonPrevious previous={!previous && props.canPreviousPage} onClick={onClickPreviousPage} />
      <ButtonPrevious previous={previous} onClick={onClickPreviousEntry} />
      {/* this p tag is created to make flexbox work better. justifyContent: "space-between", */}
      <p style={{ opacity: 0 }}> a </p>
      <ButtonNext next={next} onClick={onClickNextEntry} />
      <ButtonNext next={!next && props.canNextPage} onClick={onClickNextPage} />
    </div>
  );
};

export default connect(null, { fetchPageAndSelectTask })(TaskModalArrows);
