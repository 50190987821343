import React from "react";
import { connect, useDispatch } from "react-redux";
import { Card } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";

import {
  activeCaseSelector,
  numberOfInsuredSelector,
  numberOfBeneficiariesSelector,
  numberOfPendingCaseFilesSelector,
  auctionAccessEnabledSelector,
  numberOfOwnersSelector,
  numberOfTasksSelector,
  accountsSelector,
  casesSelector,
  loadingSelector,
} from "reducers";
import { setShouldSave, pricingSetup, fetchLifeSettlementCase, fetchPageAndSelectEntry } from "actions";
import SendCasePDFButton from "./SendCasePDFButton";
import { Tab, TabGroup } from "../../../components/Tabs";
import { URLS } from "../../../constants";
import { isAdmin, isReadMode } from "permissions";
import { ButtonSaveChanges } from "../../../components/buttons";
import BackToButton from "../../../components/buttons/BackToButton";
import { NavButton } from "../../../components/TopHeaderButtons";
import usePaginationNavigation from "../../../hooks/usePaginationNavigation";
import { useNavigate } from "react-router-dom";
import { ENDPOINTS_AND_PAGE_TO_PAGE_SELECTOR_MAPPING } from "../../../actions/pagination/constants";
import Loader from "../../../components/Loader";
import { ClipLoader } from "react-spinners";
import * as types from "../../../actions/types";
import { getLastUrlSegment } from "../../../utils";

const CaseTopHeaderNavbar = ({
  caseInfo,
  insuredInfo,
  lifeExpectancyInfo,
  isActive,
  case_title,
  pricingSetup,
  auctionNotesFormSubmitRef,
  caseOverviewFormSubmitRef,
  caseTradingSummaryFormSubmitRef,
  closingDetailFormSubmitRef,
  hasAdminPermission,
  ...props
}) => {
  if (!caseInfo) {
    return null;
  }

  const onClickCallback = () => {
    // Re-fetch the case on every click on the Tabs
    props.fetchLifeSettlementCase(caseInfo.id);
  };

  const tabs = [
    { link: `${props.managementPath}/policy/`, label: "Policy" },
    { link: `${props.managementPath}/insured/`, label: `Insured (${props.insuredCount})` },
    { link: `${props.managementPath}/owners/`, label: `Owners (${props.ownersCount})` },
    { link: `${props.managementPath}/beneficiaries/`, label: `Beneficiaries (${props.beneficiariesCount})` },
  ];
  if (hasAdminPermission && props.auctionAccessEnabled) {
    tabs.push({ link: `${props.managementPath}/trading/`, label: "Trading" });
  }
  if (hasAdminPermission) {
    tabs.push({ link: `${props.managementPath}/closing/`, label: "Closing" });
  }
  tabs.push(
    { link: `${props.managementPath}/files/`, label: `Files (${props.pendingCaseFilesCount})` },
    { link: `${props.managementPath}/tasks/`, label: `Tasks (${props.pendingTasksFilesCount})` },
  );
  if (hasAdminPermission) {
    tabs.push({ link: `${props.managementPath}/activity/`, label: `Activity Log` });
  }
  const history = useNavigate();
  const selectEntry = entry => {
    const lastSegment = getLastUrlSegment() || "policy";
    history(`${URLS.CASES}${entry.id}/${lastSegment}/`);
  };

  const { onClickPreviousPage, onClickNextPage, onClickPreviousEntry, onClickNextEntry, previous, next } =
    usePaginationNavigation({
      entryType: "CASES",
      entries: props.cases,
      entryInfo: caseInfo,
      fetchPageAndSelectEntry: props.fetchPageAndSelectEntry,
      selectEntry,
      canPreviousPage: props.canPreviousPage,
      canNextPage: props.canNextPage,
      entriesSelector: casesSelector,
    });

  const PreviousAccountButton = () => (
    <NavButton
      direction="previous"
      type="case"
      disabled={!previous && !props.canPreviousPage}
      onClick={() => (previous ? onClickPreviousEntry() : onClickPreviousPage())}
    />
  );

  const NextAccountButton = () => (
    <NavButton
      direction="next"
      type="case"
      disabled={!next && !props.canNextPage}
      onClick={() => (next ? onClickNextEntry() : onClickNextPage())}
    />
  );

  let caseTitle = caseInfo.case_title;
  if (props.loadingCase) {
    caseTitle = <ClipLoader size={22} color={"white"} />;
  } else if (caseTitle) {
    if (caseInfo.status === "D") {
      caseTitle += " (DRAFT)";
    }
  }
  return (
    <div
      style={{
        position: "fixed",
        top: "4rem",
        left: "0rem",
        width: "100%",
        zIndex: 25,
      }}
    >
      <Card style={{ border: "1px solid black", borderRadius: "0px" }}>
        <Card.Header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "black",
            padding: "0.75rem 1rem",
          }}
        >
          <h5
            className="section-title navbar-title"
            style={{
              color: "white",
              display: "block" /* Change from flex to block */,
              alignItems: "center",
              gap: "8px",
              marginBottom: 0,
              lineHeight: 2,
              whiteSpace: "normal" /* Allow text to wrap */,
              wordBreak: "break-word" /* Ensure long words break */,
              overflowWrap: "break-word" /* Alternative for word wrapping */,
              maxWidth: "100%" /* Prevent text from overflowing */,
            }}
          >
            Case: {caseTitle}
          </h5>
          <div style={{ display: "flex" }}>
            {(props.loadingCase || previous || next) && (
              <>
                <PreviousAccountButton />
                <NextAccountButton />
              </>
            )}
            <BackToButton entity="Cases" url={URLS.CASES} />
            {!props.readMode && <SendCasePDFButton />}
            <ButtonSaveChanges saveFn={props.saveFn} />
          </div>
        </Card.Header>
      </Card>
      <h3 className={"header tertiary"}>
        <TabGroup
          style={{
            width: "100%",
          }}
        >
          {tabs.map((tab, index) => {
            return (
              <Tab key={index} link={tab.link} onClickCallback={onClickCallback}>
                {tab.label}
              </Tab>
            );
          })}
        </TabGroup>
      </h3>
    </div>
  );
};

const mapStateToProps = state => {
  const hasAdminPermission = isAdmin(state);
  let pageCount = ENDPOINTS_AND_PAGE_TO_PAGE_SELECTOR_MAPPING.CASES.PAGES(state) || 0;
  let currentPage = ENDPOINTS_AND_PAGE_TO_PAGE_SELECTOR_MAPPING.CASES?.CURRENT(state) || 0;
  const loading = loadingSelector(state);
  const loadingCase = loading.case || loading.cases;

  return {
    caseInfo: activeCaseSelector(state),
    cases: casesSelector(state),
    canPreviousPage: !loadingCase && currentPage > 1,
    canNextPage: !loadingCase && currentPage < pageCount,
    insuredCount: numberOfInsuredSelector(state) || 0,
    ownersCount: numberOfOwnersSelector(state) || 0,
    beneficiariesCount: numberOfBeneficiariesSelector(state) || 0,
    pendingCaseFilesCount: numberOfPendingCaseFilesSelector(state) || 0,
    pendingTasksFilesCount: numberOfTasksSelector(state) || 0,
    auctionAccessEnabled: auctionAccessEnabledSelector(state),
    hasAdminPermission,
    readMode: isReadMode(state),
    loadingCase,
  };
};

export default connect(mapStateToProps, {
  pricingSetup,
  setShouldSave,
  fetchLifeSettlementCase,
  fetchPageAndSelectEntry,
})(CaseTopHeaderNavbar);
