import React from "react";
import { connect } from "react-redux";

import { showModal, clearSelectedLead } from "actions";
import { activeAccountSelector, loadingSelector } from "reducers";
import Table, { choiceCell, dateCell, leadScoreCell } from "components/table";
import IconLink from "../../../components/IconLink";
import { FaEdit } from "react-icons/fa";
import { LEAD_STATUS_CHOICES_MAP } from "../../../constants";
import { LEAD_ROLES_CHOICES, MARKETING_ACTIONS } from "../../leads/constants";

const AccountLeadsTable = ({ ...props }) => {
  if (!props.accountLeads) {
    return null;
  }

  const columns = [
    {
      Header: "Lead ID",
      Cell: ({ row }) => {
        const leadId = row.original.system_id;
        if (leadId) {
          return (
            <div className="text-wrap">
              <a href={`/leads/${row.original.id}/overview`} target="_blank" rel="noreferrer">
                {leadId}
              </a>
            </div>
          );
        } else {
          return "";
        }
      },
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => {
        if (row.original.status) {
          return LEAD_STATUS_CHOICES_MAP[row.original.status];
        } else {
          return "";
        }
      },
    },
    {
      Header: "First Name",
      accessor: "first_name",
    },
    {
      Header: "Last Name",
      accessor: "last_name",
    },
    {
      Header: "Phone",
      accessor: "phone",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Insured Age",
      accessor: "insured_1_age",
    },
    {
      Header: "Insured Health",
      accessor: "insured_1_health",
    },
    {
      Header: "Lead Role",
      accessor: "role",
      Cell: choiceCell(LEAD_ROLES_CHOICES),
    },
    {
      Header: "Lead Source",
      accessor: "marketing_action",
      Cell: choiceCell(MARKETING_ACTIONS),
    },
    {
      Header: "Lead Score",
      accessor: "score",
      Cell: leadScoreCell,
    },
    {
      Header: "Date Created",
      accessor: "date_created",
      Cell: dateCell,
    },
  ];

  return (
    <Table
      columns={columns}
      data={props.accountLeads}
      initialPageSize={25}
      showPaginationResults={false}
      defaultSort={true}
      defaultPagination={true}
      paginated={true}
      emptyMessage={"No Account Leads found"}
      sortBy={[{ id: "created_date", desc: true }]}
      darker={true}
    />
  );
};

const mapStateToProps = state => {
  const accountInfo = activeAccountSelector(state);
  const accountLeads = accountInfo.leads;

  return {
    accountLeads,
    loading: loadingSelector(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
  clearSelectedLead,
})(AccountLeadsTable);
