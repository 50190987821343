import React from "react";
import { connect } from "react-redux";
import { Card } from "react-bootstrap";

import { selectFunderEntity, showModal } from "actions";
import { ButtonDelete } from "components/buttons";
import SectionDetails from "../../components/SectionDetails";
import { DELETE_FUNDER_ENTITY_MODAL } from "../../components/modals";
import { managementChoicesSelector } from "../../reducers";

const FunderEntityInfo = ({ isActive, info, deleteHandler, deleteText, ...props }) => {
  if (!info) {
    return null;
  }

  let intermediary_names = [];
  if (info.securities_intermediaries) {
    for (const escrow_data of info.securities_intermediaries) {
      intermediary_names.push(escrow_data.intermediary_name);
    }
  }
  const formatted_intermediaries = intermediary_names.join(", ");

  let escrow_agent_names = [];
  let escrowAgentChoices = props.escrowAgentChoices?.getChoices() || [];
  if (info.escrow_agents) {
    let escrow_agents_list = info.escrow_agents.split(",");
    for (let escrow_agent_id of escrow_agents_list) {
      let data = escrowAgentChoices.find(e => e.value === parseInt(escrow_agent_id));
      if (data) {
        escrow_agent_names.push(data.label);
      }
    }
  }
  const formatted_escrow_agents = escrow_agent_names.join(", ");

  return (
    <div
      className={`section-card shadowed ${isActive && "active"}`}
      onClick={() => {
        props.selectFunderEntity(info.id);
      }}
      style={{ cursor: "pointer" }}
    >
      <Card>
        <Card.Header>
          <h5 className="section-title">{props.title || `Funder Entity (${props.idx})`}</h5>
        </Card.Header>
        <Card.Body>
          <SectionDetails
            details={{
              "Entity Name": info.name,
              Status: info.status,
              "Securities Intermediar(ies)": formatted_intermediaries,
              "Escrow Agent(s)": formatted_escrow_agents,
            }}
          />
        </Card.Body>
        <Card.Footer>
          <ButtonDelete
            text={deleteText}
            onClick={() => {
              props.showModal(DELETE_FUNDER_ENTITY_MODAL, info);
            }}
          />
        </Card.Footer>
      </Card>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const choices = managementChoicesSelector(state);
  return {
    escrowAgentChoices: choices.escrow_agents,
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectFunderEntity,
})(FunderEntityInfo);
