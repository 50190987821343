import React from "react";
import { connect } from "react-redux";

import { activeLeadSelector, leadsSelector, loadingLeadsSelector } from "reducers";
import { URLS } from "../../constants";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  fetchLeadEngagementActivities,
  fetchLeadTasks,
  fetchPageAndSelectEntry,
  selectLead,
  setShouldSave,
  showModal,
} from "actions";
import { isAdmin } from "permissions";
import { ENDPOINTS_AND_PAGE_TO_PAGE_SELECTOR_MAPPING } from "../../actions/pagination/constants";
import { NavButton } from "../../components/TopHeaderButtons";
import { ButtonSaveChanges } from "../../components/buttons";
import BackToButton from "../../components/buttons/BackToButton";
import usePaginationNavigation from "hooks/usePaginationNavigation";
import { ClipLoader } from "react-spinners";

const LeadTopHeader = ({ leadInfo, ...props }) => {
  const history = useNavigate();

  const selectEntry = lead => {
    history(`${URLS.LEADS}${lead.id}/overview/`);
    props.selectLead(lead.id);
    props.fetchLeadTasks(lead.id);
    props.fetchLeadEngagementActivities(lead.id);
  };

  const { onClickPreviousPage, onClickNextPage, onClickPreviousEntry, onClickNextEntry, previous, next } =
    usePaginationNavigation({
      entryType: "LEADS",
      entries: props.leads,
      entryInfo: leadInfo,
      fetchPageAndSelectEntry: props.fetchPageAndSelectEntry,
      selectEntry,
      canPreviousPage: props.canPreviousPage,
      canNextPage: props.canNextPage,
      entriesSelector: leadsSelector,
    });

  const PreviousLeadButton = () => (
    <NavButton
      direction="previous"
      type="lead"
      disabled={!previous && !props.canPreviousPage}
      onClick={() => (previous ? onClickPreviousEntry() : onClickPreviousPage())}
    />
  );

  const NextLeadButton = () => (
    <NavButton
      direction="next"
      type="lead"
      disabled={!next && !props.canNextPage}
      onClick={() => (next ? onClickNextEntry() : onClickNextPage())}
    />
  );

  let leadName = "";
  leadName += leadInfo?.first_name && leadInfo?.last_name ? ` ${leadInfo.last_name}, ${leadInfo.first_name} | ` : "";
  leadName += leadInfo?.system_id ? leadInfo.system_id : "";

  return (
    <div
      style={{
        position: "fixed",
        top: "4rem",
        left: "0rem",
        width: "100%",
        zIndex: 25,
      }}
    >
      <Card style={{ border: "1px solid black", borderRadius: "0px" }}>
        <Card.Header
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "black",
            padding: "0.75rem 1rem",
          }}
        >
          {/* Left: Lead Name */}
          <h5 style={{ color: "white", margin: "5px 0 0 1rem", display: "flex", alignItems: "center", gap: "8px" }}>
            Lead: {props.loading ? <ClipLoader size={22} color={"white"} /> : leadName}
          </h5>

          {/* Center: Previous and Next Buttons */}
          {(props.loading || previous || next) && (
            <div className={"prevNextButtonContainer"}>
              <PreviousLeadButton />
              <NextLeadButton />
            </div>
          )}

          {/* Right: Back and Save Buttons */}
          <div style={{ display: "flex", gap: "0.75rem" }}>
            <BackToButton entity="Leads" url={URLS.LEADS} />;
            <ButtonSaveChanges saveFn={props.saveFn} />
          </div>
        </Card.Header>
      </Card>
    </div>
  );
};

const mapStateToProps = state => {
  let pageCount = ENDPOINTS_AND_PAGE_TO_PAGE_SELECTOR_MAPPING.LEADS.PAGES(state) || 0;
  let currentPage = ENDPOINTS_AND_PAGE_TO_PAGE_SELECTOR_MAPPING.LEADS?.CURRENT(state) || 0;
  let loading = loadingLeadsSelector(state);

  return {
    hasAdminPermission: isAdmin(state),
    leadInfo: activeLeadSelector(state),
    leads: leadsSelector(state),
    canPreviousPage: !loading && currentPage > 1,
    canNextPage: !loading && currentPage < pageCount,
    loading,
  };
};

export default connect(mapStateToProps, {
  showModal,
  setShouldSave,
  selectLead,
  fetchLeadTasks,
  fetchLeadEngagementActivities,
  fetchPageAndSelectEntry,
})(LeadTopHeader);
