import React, { useRef } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import * as notifications from "../../../../notifications";
import { Select, FormCollapsible, SubmitButton, TextInput, EditorField } from "components/forms";
import {
  sendMarketActivityEmail,
  fetchActivityLog,
  updateMarketActivityFiles,
  fetchEmailTemplates,
  fetchInsured,
  generateMarketActivityFiles,
} from "actions";
import {
  accountsSelector,
  activeMarketAuctionActivitySelector,
  activeCaseIdSelector,
  activeCaseSelector,
  agentsChoicesSelector,
  emailTemplateSelector,
  insuredListSelector,
  managementChoicesSelector,
  loadingSelector,
  activeMarketActivityFilesSelector,
} from "reducers";
import MultipleFileDropZone from "components/MultipleFileDropZone";
import { moneyDisplay, toTitleCase } from "utils";
import File from "../../../../components/File";
import { prepareTags } from "./tags";

const SendMarketActivityEmail = ({ insuredInfo, ...props }) => {
  const formikRef = useRef();

  React.useEffect(() => {
    props.fetchEmailTemplates();
  }, [props.activeCaseId]);

  const getTemplateName = id => props.marketEmailTemplates[id].name;
  let market_activity_email_template;
  for (let template of Object.values(props.marketEmailTemplates)) {
    if (template.name === "Sales Team Bid Notification") {
      market_activity_email_template = template;
    }
  }

  const getInsuredNames = () => {
    if (insuredInfo.length === 1) {
      return `${insuredInfo[0].last_name}, ${insuredInfo[0].first_name}`;
    } else if (insuredInfo.length === 2) {
      return `${insuredInfo[0].last_name}, ${insuredInfo[0].first_name} & ${insuredInfo[1].last_name}, ${insuredInfo[1].first_name}`;
    }
  };

  const prepareSubject = title => {
    let subject = title;
    let insuredNames = getInsuredNames() || "";
    subject = subject.replace("{Insured Names}", insuredNames);
    subject = subject.replace("{Application ID}", props.caseInfo.submission_id);
    return subject;
  };

  const prepareBody = html => {
    let body = html;
    let { insured_names, insured_ages, insured_gender } = prepareTags(insuredInfo);
    let carrier_name = props.caseInfo.carrier_name;

    let marketType = props.caseInfo.market_type || "";
    if (marketType) {
      marketType = toTitleCase(marketType);
    }

    body = body.replace("{Application ID}", props.caseInfo.submission_id);
    body = body.replace("{Insured Names}", insured_names);
    body = body.replace("{Insured Ages}", insured_ages);
    body = body.replace("{Insured Gender}", insured_gender);
    body = body.replace("{Face Amount}", moneyDisplay(props.caseInfo.face_amount));
    body = body.replace("{Carrier}", carrier_name);
    body = body.replace("{Product Type}", props.caseInfo.product_type);
    body = body.replace("{Market Type}", marketType);
    let auction_manager = props.auctionManagerChoices
      .getChoices()
      .find(a => a.value === props.caseInfo.auction_manager);
    if (auction_manager) {
      let auction_manager_name = auction_manager.label || "";
      body = body.replace("{Trading Manager Agency User Name}", auction_manager_name);
    }

    return body;
  };

  const prepareRecipients = () => {
    // Email sent to Sales Rep
    let recipient_list = [];

    // Sales Reps
    let sales_representative_id = props.caseInfo.sales_representative;
    let choices = props.salesRepChoices.getChoices();
    let sales_representative = choices.find(a => a.value === sales_representative_id);
    pushNewEmail(sales_representative, recipient_list);

    return recipient_list.join("; ");
  };

  const prepareCC = () => {
    // Email CC to Sales Manager, National Account Manager, Trading Manager, aaron & bmarz
    let recipient_list = [];

    let sales_manager_id = props.caseInfo.sales_manager;
    let national_account_manager_id = props.caseInfo.national_account_manager;
    let choices = props.salesRepChoices.getChoices();
    let sales_manager = choices.find(a => a.value === sales_manager_id);
    let national_account_manager = choices.find(a => a.value === national_account_manager_id);
    pushNewEmail(sales_manager, recipient_list);
    pushNewEmail(national_account_manager, recipient_list);

    // Trading Manager is taken from auctionManagerChoices
    let trading_manager_id = props.caseInfo.auction_manager;
    let trading_manager = props.auctionManagerChoices.getChoices().find(a => a.value === trading_manager_id);
    pushNewEmail(trading_manager, recipient_list);

    recipient_list.push("Aaron Giroux <aaron@LifeRocCapital.com>");
    recipient_list.push("Brandon Marz <bmarz@LifeRocCapital.com>");

    return recipient_list.join("; ");
  };

  const pushNewEmail = (obj, recipient_list) => {
    if (obj && obj.email && recipient_list.indexOf(obj.email) === -1) {
      recipient_list.push(`${obj.label} <${obj.email}>`);
    }
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        enableReinitialize
        initialValues={{
          recipients: prepareRecipients(),
          cc: prepareCC(),
          email_template: market_activity_email_template.id,
          subject: prepareSubject(market_activity_email_template.subject),
          body: prepareBody(market_activity_email_template.html),
        }}
        onSubmit={async (values, { setSubmitting, setErrors }) => {
          try {
            let payload = {
              ...values,
            };
            // Used to decide when to send the default files in the backend
            if (props.activeMarketActivityFiles.offer_letter) {
              payload.offer_letter = true;
            }
            if (props.activeMarketActivityFiles.contract_form) {
              payload.contract_form = true;
            }
            const response = await props.sendMarketActivityEmail(payload, props.activeMarketAuctionActivity.id);
            if (response && response.error) {
              notifications.error(response.error);
              setSubmitting(false);
              return false;
            }
            notifications.success("Email sent successfully");
            props.fetchActivityLog(props.activeCaseId);
          } catch (error) {
            setErrors(error.response.data);
            notifications.error("Error sending email");
          }
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, values, setFieldValue }) => {
          return (
            <>
              <FormCollapsible onSubmit={handleSubmit} title="Send Market Activity Email">
                <Form.Row>
                  <Select
                    label="Email Template"
                    name="email_template"
                    options={props.templateChoices}
                    disabled={true}
                  />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Recipients" name="recipients" as="textarea" rows={1} disabled />
                </Form.Row>
                <Form.Row>
                  <TextInput label="CC" name="cc" placeholder="Enter CC Email(s)" as="textarea" rows={2} />
                </Form.Row>
                <Form.Row>
                  <span
                    style={{ marginBottom: "0.5rem", height: "2rem", paddingTop: "0.5rem", marginLeft: 5 }}
                    className="form-label__label"
                  >
                    Attachments
                  </span>
                  <MultipleFileDropZone setFieldValue={setFieldValue} />
                </Form.Row>
                <Form.Row>
                  <Select
                    label="Letter Type"
                    name="email_template"
                    options={props.letterTypeChoices}
                    defaultValue={[props.letterTypeChoices[0]]}
                    onChange={option => {
                      props.generateMarketActivityFiles(props.activeMarketAuctionActivity, option.value);
                    }}
                  />
                </Form.Row>
                <Form.Row>
                  {props.loading.marketActivityFiles ? (
                    "Generating Files..."
                  ) : (
                    <>
                      {props.activeMarketActivityFiles.offer_letter ? (
                        <File
                          file={props.activeMarketActivityFiles.offer_letter}
                          deleteFile={() => {
                            props.updateMarketActivityFiles(props.activeMarketAuctionActivity, {
                              offer_letter: null,
                              contract_form: props.activeMarketActivityFiles.contract_form,
                            });
                          }}
                        />
                      ) : null}
                      {props.activeMarketActivityFiles.contract_form ? (
                        <File
                          file={props.activeMarketActivityFiles.contract_form}
                          deleteFile={() => {
                            props.updateMarketActivityFiles(props.activeMarketAuctionActivity, {
                              offer_letter: props.activeMarketActivityFiles.offer_letter,
                              contract_form: null,
                            });
                          }}
                        />
                      ) : null}
                    </>
                  )}
                </Form.Row>
                <Form.Row>
                  <TextInput label="Subject" name="subject" />
                </Form.Row>
                <Form.Row>
                  <EditorField
                    label={values.email_template ? getTemplateName(values.email_template) : "Email Template"}
                    name="body"
                    isRequired
                  />
                </Form.Row>
                <SubmitButton defaultText={"Send"} />
              </FormCollapsible>
            </>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  const agentsChoices = agentsChoicesSelector(state);
  const marketEmailTemplates = emailTemplateSelector(state);

  let templateChoices = [];
  for (let template of Object.values(marketEmailTemplates)) {
    templateChoices.push({ label: template.name, value: template.id });
  }

  let letterTypeChoices = [
    { label: "Broker", value: 1 },
    { label: "Direct", value: 2 },
  ];

  return {
    loading: loadingSelector(state),
    activeCaseId: activeCaseIdSelector(state),
    activeMarketAuctionActivity: activeMarketAuctionActivitySelector(state),
    templateChoices,
    marketEmailTemplates,
    caseInfo: activeCaseSelector(state),
    accounts: accountsSelector(state),
    insuredInfo: insuredListSelector(state),
    auctionManagerChoices: agentsChoices.auction_managers,
    salesRepChoices: choices.case_sales_representatives,
    activeMarketActivityFiles: activeMarketActivityFilesSelector(state),
    letterTypeChoices,
  };
};

export default connect(mapStateToProps, {
  sendMarketActivityEmail,
  fetchActivityLog,
  fetchEmailTemplates,
  fetchInsured,
  updateMarketActivityFiles,
  generateMarketActivityFiles,
})(SendMarketActivityEmail);
