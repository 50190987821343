import React from "react";
import { connect, useDispatch } from "react-redux";

import * as types from "../../../actions/types";
import { SEND_ACCOUNT_EMAIL_MODAL, SEND_LEAD_EMAIL_MODAL } from "../../../components/modals";
import { showModal } from "actions";

import { FaEnvelope } from "react-icons/fa";

const SendAccountEmailFromTemplateIcon = ({ mail, accountId, ...props }) => {
  const dispatch = useDispatch();
  return (
    <FaEnvelope
      onClick={() => {
        dispatch({
          type: types.SELECT_SINGLE_ACCOUNT,
          payload: { id: accountId },
        });
        dispatch({
          type: types.SET_EMAIL_TEMPLATE,
          payload: "Account Notification",
        });
        props.showModal(SEND_ACCOUNT_EMAIL_MODAL, { mail: mail });
      }}
      key="create"
      style={{ marginLeft: "1rem" }}
    ></FaEnvelope>
  );
};

const SendLeadEmailFromTemplateIcon = ({ mail, leadId, ...props }) => {
  const dispatch = useDispatch();
  return (
    <FaEnvelope
      onClick={() => {
        dispatch({
          type: types.SELECT_SINGLE_LEAD,
          payload: { id: leadId },
        });
        dispatch({
          type: types.SET_EMAIL_TEMPLATE,
          payload: "Lead Notification",
        });
        props.showModal(SEND_LEAD_EMAIL_MODAL, { mail: mail });
      }}
      key="create"
      style={{ marginLeft: "1rem" }}
    ></FaEnvelope>
  );
};

const ConnectedSendAccountEmailFromTemplateIcon = connect(null, { showModal })(SendAccountEmailFromTemplateIcon);
const ConnectedSendLeadEmailFromTemplateIcon = connect(null, { showModal })(SendLeadEmailFromTemplateIcon);

export { ConnectedSendAccountEmailFromTemplateIcon as SendAccountEmailFromTemplateIcon };
export { ConnectedSendLeadEmailFromTemplateIcon as SendLeadEmailFromTemplateIcon };
