import React from "react";
import { FaAngleRight } from "react-icons/fa";

export default ({ onClick, children, className, next = false, ...props }) => {
  if (!next) return "";
  return (
    <button className={`btn btn--secondary ${className}`} onClick={onClick} {...props}>
      Next <FaAngleRight />
      {children}
    </button>
  );
};
