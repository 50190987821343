import React from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";

import {
  BooleanSelect,
  Collapsible,
  DateInput,
  DateTimeInput,
  EditorField,
  StateSelect,
  SubmitButton,
  TextInput,
} from "components/forms";
import { activeAccountSelector, shouldSaveSelector } from "reducers";
import { Form } from "react-bootstrap";
import { partial } from "../../../utils";
import { isAdmin } from "permissions";
import {
  createEmptyEntityAccountForAccount,
  editAccount,
  unsetShouldSave,
  showModal,
  selectManageEntity,
  selectAccountTask,
  selectAccountAffiliation,
} from "actions";
import AccountTaskTable from "./tasks/AccountTaskTable";
import * as notifications from "../../../notifications";
import {
  ACCOUNT_STATUS,
  ACCOUNT_TYPES,
  LINES_OF_BUSINESS,
  MARKETING_SOURCES,
  PRACTICE_TYPES,
  SOURCE_OF_ACCOUNTS,
  YES_NO_PAST,
} from "../constants";
import { FaEdit, FaPlus } from "react-icons/fa";
import {
  ACCOUNT_AFFILIATION_MODAL,
  ACCOUNT_TASK_MODAL,
  CREATE_ACCOUNT_MODAL,
  MANAGE_ENTITY_MODAL,
} from "../../../components/modals";
import { findAccountTypeFromLabel, getEntityIdFromAccount } from "../utils";
import { RecordsTable } from "../../../components/table";
import AccountAffiliationTable from "./affiliations/AccountAffiliationTable";
import TeamMemberTable from "./teamMembers/TeamMemberTable";
import EngagementActivitiesTable from "./EngagementActivitiesTable";
import SelectInlineLabel from "../../../components/forms/selects/SelectInlineLabel";
import AccountLeadsTable from "./AccountLeadsTable";
import { SendLeadEmailFromTemplateIcon } from "../emails/SendEmailFromTemplateIcon";

const AccountForm = ({
  accountInfo,
  entityType,
  entityId,
  individualType,
  activeHierarchySalesRepName,
  availableEntitiesForUser,
  hasAdminPermission,
  ...props
}) => {
  if (!accountInfo) {
    // Still didn't fetch data
    return null;
  }

  const dispatch = useDispatch();

  const submitRef = React.useRef();
  let entitiesListOptions = availableEntitiesForUser;

  React.useEffect(() => {
    if (props.shouldSave) {
      submitRef.current.click();
    }
  }, [props.shouldSave]);

  React.useEffect(() => {
    if (accountInfo.associated_entity && accountInfo.associated_entity_name) {
      const associatedEntityChoice = {
        label: accountInfo.associated_entity_name,
        value: accountInfo.associated_entity,
      };
      if (!availableEntitiesForUser.some(e => e.value === accountInfo.associated_entity)) {
        // if the user has no access to that entity, add it as an option so it can be seen in the Select
        // adds to entitiesListOptions so after changing it, doesn't lose previous choice
        entitiesListOptions = [...entitiesListOptions, associatedEntityChoice];
      }
    }
  }, [accountInfo]);

  const accountStatusTooltipText =
    "<b>Actionable Account:</b> May have a case eminently.<br><br>" +
    "<b>Active Account:</b> Have submitted business previously.<br><br>" +
    "<b>Lead Account:</b> New Account Lead.<br><br>" +
    "<b>Pending Case Account:</b> Has current Case pending.<br><br>" +
    "<b>Qualified Account:</b> No business yet but have done a settlement previously or have a good book of senior life insurance clients.<br><br>" +
    "<b>Targeted Account:</b> New National Account or Targeted List.";

  return (
    <Formik
      enableReinitialize
      initialValues={{
        ...accountInfo,
        activeHierarchySalesRepName,
        availableEntitiesForUser,
        entitiesListOptions,
        associated_entity: accountInfo.associated_entity ? accountInfo.associated_entity : null,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        const method = partial(props.editAccount, accountInfo.id);
        delete values.active_campaign_contact_id;
        delete values.active_campaign_list_id;
        delete values.active_campaign_list;
        delete values.active_campaign_error;

        try {
          await method(values);
          notifications.success("Account saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Account");
        }
        props.unsetShouldSave();
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        const RenderEntityButtons = () => {
          // If an Associated Entity is selected, show Manage Entity button
          if (
            values.associated_entity &&
            typeof values.associated_entity === "number" &&
            availableEntitiesForUser.some(e => e.value === values.associated_entity)
          ) {
            return (
              <button
                className={`btn btn-sm btn--secondary`}
                style={{
                  float: "right",
                  fontSize: 12,
                  padding: "2px 5px",
                }}
                type="button"
                onClick={() => {
                  props.selectManageEntity(values.associated_entity);
                  props.showModal(MANAGE_ENTITY_MODAL, { accountType: entityType });
                }}
              >
                <FaEdit />
                Manage Entity
              </button>
            );
          }
          // If Account is type Individual, show Add Entity button
          if (values.type === 0 && hasAdminPermission) {
            return (
              <button
                className={`btn btn-sm btn--secondary`}
                style={{
                  float: "right",
                  fontSize: 12,
                  padding: "2px 5px",
                  marginRight: "5px",
                  marginBottom: "4px",
                }}
                type="button"
                onClick={() => {
                  props.showModal(CREATE_ACCOUNT_MODAL, { accountType: entityType });
                }}
              >
                <FaPlus />
                Add Entity
              </button>
            );
          }
        };

        return (
          <div>
            <Form onSubmit={handleSubmit} className={"form shadowed"} style={{ marginBottom: "-10px" }} {...props}>
              <Collapsible title="Account Detail" darker={true}>
                <Form.Row>
                  <SelectInlineLabel
                    label="Account Type"
                    name="type"
                    placeholder="Select Account Type"
                    options={ACCOUNT_TYPES}
                    md={4}
                  />
                  <TextInput label="Account Name" name="source_name" inline={true} disabled md={4} />
                  {values.type === 0 ? (
                    accountInfo && (
                      <div className={`col-md${"-" + 4} padding0 d-flex`}>
                        <SelectInlineLabel
                          label="Entity Name"
                          name="associated_entity"
                          placeholder="Select Entity"
                          options={entitiesListOptions}
                        />
                        <RenderEntityButtons />
                      </div>
                    )
                  ) : (
                    <TextInput label="Entity Name" name="entity_name" inline={true} />
                  )}
                </Form.Row>
                <Form.Row>
                  <SelectInlineLabel
                    label="Account Status"
                    name="status"
                    placeholder="Select Account Status"
                    options={ACCOUNT_STATUS}
                    md={4}
                    tooltip={accountStatusTooltipText}
                  />
                  <TextInput label="Account ID" name="system_id" inline={true} md={2} disabled />
                  <TextInput label="National Producer Number" name="national_producer_number" inline={true} disabled />
                  <TextInput label="Sales Representative" name="activeHierarchySalesRepName" inline={true} disabled />
                </Form.Row>
                {values.type === 0 ? (
                  <>
                    <Form.Row>
                      <TextInput label="First Name" name="first_name" inline={true} />
                      <TextInput label="Middle Name" name="middle_name" inline={true} />
                      <TextInput label="Last Name" name="last_name" inline={true} />
                    </Form.Row>
                    <Form.Row>
                      <TextInput label="Suffix" name="suffix" md={2} inline={true} />
                      <TextInput label="Nick Name" name="nick_name" md={2} inline={true} />
                      <TextInput label="Title" name="title" md={4} inline={true} />
                      <DateInput label="Date of Birth" name="date_of_birth" disabled md={4} />
                    </Form.Row>
                  </>
                ) : null}
                <Form.Row>
                  <TextInput label="Work Phone" phone={true} name="work_phone" inline={true} />
                  <TextInput label="Cell Phone" phone={true} name="cell_phone" inline={true} />
                  <TextInput label="Other Phone" phone={true} name="other_phone" inline={true} />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Work Extension" name="work_extension" inline={true} />
                  <TextInput label="Fax Number" name="fax" inline={true} />
                  <TextInput label="Website" name="website" inline={true} />
                </Form.Row>
                <Form.Row>
                  <TextInput
                    label="Work Email"
                    lowercase={true}
                    name="work_email"
                    inline={true}
                    append={
                      values.work_email ? (
                        <SendLeadEmailFromTemplateIcon mail={values.work_email} leadId={accountInfo.id} />
                      ) : null
                    }
                  />
                  <TextInput
                    label="Other Email"
                    lowercase={true}
                    name="other_email"
                    inline={true}
                    append={
                      values.other_email ? (
                        <SendLeadEmailFromTemplateIcon mail={values.other_email} leadId={accountInfo.id} />
                      ) : null
                    }
                  />
                  <TextInput
                    label="Case Status Email"
                    lowercase={true}
                    name="email"
                    inline={true}
                    append={
                      values.email ? (
                        <SendLeadEmailFromTemplateIcon mail={values.email} leadId={accountInfo.id} />
                      ) : null
                    }
                  />
                  <TextInput
                    label="Other Email #2"
                    lowercase={true}
                    name="other_email2"
                    inline={true}
                    append={
                      values.other_email2 ? (
                        <SendLeadEmailFromTemplateIcon mail={values.other_email2} leadId={accountInfo.id} />
                      ) : null
                    }
                  />
                </Form.Row>
                <Form.Row>
                  <BooleanSelect label="Work Email Opt Out" name="email_opt_out" inline={true} />
                  <BooleanSelect label="Other Email Opt Out" name="other_email_opt_out" inline={true} />
                  <BooleanSelect label="Case Email Opt Out" name="case_email_opt_out" inline={true} />
                  <BooleanSelect label="Other Email #2 Opt Out" name="other_email2_opt_out" inline={true} />
                </Form.Row>
                <Form.Row>
                  <TextInput label="Street Address" name="street_address" inline={true} />
                </Form.Row>
                <Form.Row>
                  <TextInput label="City" name="city" inline={true} />
                  <StateSelect label="State" name="state" placeholder="Select State" inline={true} />
                  <TextInput label="Zip Code" name="zipcode" inline={true} />
                </Form.Row>
                <Form.Row>
                  <TextInput label="LinkedIn" name="linkedin" inline={true} />
                  <TextInput label="Study Groups" name="study_groups" inline={true} />
                </Form.Row>
                <Form.Row>
                  <EditorField label="Notes" name="notes" inline={true} />
                </Form.Row>
                <SubmitButton innerRef={submitRef} style={{ display: "none" }} />
              </Collapsible>
              <RecordsTable
                title="Tasks Detail"
                createButtonLabel="Add Task"
                onCreateClick={e => {
                  e.preventDefault();
                  props.selectAccountTask(null);
                  props.showModal(ACCOUNT_TASK_MODAL);
                }}
                Table={AccountTaskTable}
                darker={true}
              />
              <RecordsTable title="Account Leads" actionButtons={[]} Table={AccountLeadsTable} darker={true} />
              <Collapsible title="Practice Profile">
                <Form.Row>
                  <SelectInlineLabel
                    label="Practice Type"
                    name="practice_type"
                    placeholder="Select Practice Type"
                    options={PRACTICE_TYPES}
                  />
                  <SelectInlineLabel
                    label="Main Line of Business"
                    name="main_line_of_business"
                    placeholder="Select Main Line of ..."
                    options={LINES_OF_BUSINESS}
                  />
                  <SelectInlineLabel
                    label="Accounting"
                    name="accounting"
                    placeholder="Select Accounting"
                    options={YES_NO_PAST}
                  />
                  <SelectInlineLabel
                    label="Annuities"
                    name="annuities"
                    placeholder="Select Annuities"
                    options={YES_NO_PAST}
                  />
                </Form.Row>
                <Form.Row>
                  <SelectInlineLabel
                    label="Banking"
                    name="banking"
                    placeholder="Select Banking"
                    options={YES_NO_PAST}
                  />
                  <SelectInlineLabel
                    label="Disability Insurance"
                    name="disability_insurance"
                    placeholder="Select Disability Insurance"
                    options={YES_NO_PAST}
                  />
                  <SelectInlineLabel
                    label="Health Insurance"
                    name="health_insurance"
                    placeholder="Select Health Insurance"
                    options={YES_NO_PAST}
                  />
                  <SelectInlineLabel label="Legal" name="legal" placeholder="Select Legal" options={YES_NO_PAST} />
                </Form.Row>
                <Form.Row>
                  <SelectInlineLabel
                    label="Life Insurance"
                    name="life_insurance"
                    placeholder="Select Life Insurance"
                    options={YES_NO_PAST}
                  />
                  <SelectInlineLabel
                    label="Life Settlements"
                    name="life_settlements"
                    placeholder="Select Life Settlements"
                    options={YES_NO_PAST}
                  />
                  <SelectInlineLabel
                    label="Premium Finance"
                    name="premium_finance"
                    placeholder="Select Premium Finance"
                    options={YES_NO_PAST}
                  />
                  <SelectInlineLabel
                    label="Medicare"
                    name="medicare"
                    placeholder="Select Medicare"
                    options={YES_NO_PAST}
                  />
                </Form.Row>
                <Form.Row>
                  <SelectInlineLabel
                    label="Property and Casualty"
                    name="property_and_casualty"
                    placeholder="Select Property and ..."
                    options={YES_NO_PAST}
                  />
                  <SelectInlineLabel
                    label="Registered Investment Advice"
                    name="registered_investment_advice"
                    placeholder="Select Registered ..."
                    options={YES_NO_PAST}
                    md={3}
                  />
                  <SelectInlineLabel
                    label="Securities"
                    name="securities"
                    placeholder="Select Securities"
                    options={YES_NO_PAST}
                    md={3}
                  />
                  <SelectInlineLabel
                    label="Trust Services"
                    name="trust_services"
                    placeholder="Select Trust Services"
                    options={YES_NO_PAST}
                    md={3}
                  />
                </Form.Row>
              </Collapsible>
              <Collapsible title="Designations" darker={true}>
                <Form.Row>
                  <SelectInlineLabel label="CFP" name="CFP" placeholder="Select CFP" options={YES_NO_PAST} />
                  <SelectInlineLabel label="CHFC" name="CHFC" placeholder="Select CHFC" options={YES_NO_PAST} />
                  <SelectInlineLabel label="CLU" name="CLU" placeholder="Select CLU" options={YES_NO_PAST} />
                </Form.Row>
                <Form.Row>
                  <SelectInlineLabel
                    label="LUTCF"
                    name="LUTCF"
                    placeholder="Select LUTCF"
                    options={YES_NO_PAST}
                    md={4}
                  />
                  <SelectInlineLabel label="MDRT" name="MDRT" placeholder="Select MDRT" options={YES_NO_PAST} md={4} />
                </Form.Row>
              </Collapsible>
              <RecordsTable
                title="Affiliations"
                createButtonLabel="Add Affiliation"
                onCreateClick={() => {
                  props.selectAccountAffiliation(null);
                  props.showModal(ACCOUNT_AFFILIATION_MODAL);
                }}
                Table={AccountAffiliationTable}
                darker={true}
              />
              <RecordsTable
                title="Team Members"
                createButtonLabel="Add Team Member"
                onCreateClick={() => {
                  props.showModal(CREATE_ACCOUNT_MODAL, { accountType: individualType, entityId });
                }}
                buttonDisabled={!entityId}
                Table={TeamMemberTable}
                darker={true}
              />
              <Collapsible title="Qualitative Insight" darker={true}>
                <Form.Row>
                  <SelectInlineLabel
                    label="Data Source"
                    name="source_of_account"
                    placeholder="Select Data Source"
                    options={SOURCE_OF_ACCOUNTS}
                    disabled={!hasAdminPermission}
                    md={4}
                  />
                  <SelectInlineLabel
                    label="Marketing Source"
                    name="marketing_source"
                    placeholder="Select Marketing Source"
                    options={MARKETING_SOURCES}
                    md={4}
                  />
                </Form.Row>
                <Form.Row>
                  <TextInput
                    label="Last Marketing Engagement"
                    name="last_marketing_engagement"
                    placeholder="TBD"
                    inline={true}
                    disabled
                  />
                  <TextInput label="Last Case Submit" name="last_case_submit" disabled inline={true} />
                  <TextInput label="Last Case Paid" name="last_case_paid" disabled inline={true} />
                </Form.Row>
                <Form.Row>
                  <DateInput label="Date Account Created" name="date_created" disabled />
                  <DateTimeInput label="Date Account Last Modified" name="last_edit_date" disabled inline={true} />
                  <TextInput label="Last Modified By" name="last_edited_by_name" disabled inline={true} />
                </Form.Row>
              </Collapsible>
            </Form>
            <Form
              onSubmit={e => {
                e.preventDefault();
              }}
              className={"form shadowed"}
              style={{ marginBottom: "-10px" }}
              {...props}
            >
              <RecordsTable
                title="Engagement Activity"
                actionButtons={[]}
                Table={EngagementActivitiesTable}
                darker={true}
              />
              <Collapsible title="Active Campaign" darker={true}>
                <Form.Row>
                  <TextInput label="Contact" name="active_campaign_contact_id" disabled inline={true} />
                  <TextInput label="Subscribed to List" name="_active_campaign_list" disabled inline={true} />
                </Form.Row>
                {values.active_campaign_error && (
                  <Form.Row>
                    <TextInput label="Error" name="active_campaign_error" disabled inline={true} />
                  </Form.Row>
                )}
              </Collapsible>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const accountInfo = activeAccountSelector(state);

  return {
    accountInfo,
    shouldSave: shouldSaveSelector(state),
    hasAdminPermission: isAdmin(state),
    entityId: accountInfo ? getEntityIdFromAccount(accountInfo) : null,
    entityType: findAccountTypeFromLabel("Entity"),
    individualType: findAccountTypeFromLabel("Individual"),
  };
};

export default connect(mapStateToProps, {
  editAccount,
  unsetShouldSave,
  createEmptyEntityAccountForAccount,
  showModal,
  selectManageEntity,
  selectAccountTask,
  selectAccountAffiliation,
})(AccountForm);
