import React from "react";
import { connect } from "react-redux";

import { activeStateSelector, activeTabSelector } from "reducers";
import { FaArrowLeft } from "react-icons/fa";
import { PERMISSIONS, URLS } from "../../constants";
import { Card } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Tab, TabGroup } from "../../components/Tabs";
import { setShouldSave, showModal } from "actions";
import { selectStateBrokerFile, selectStateProviderFile } from "../../reducers/life_settlement/management/states";
import { isAdmin } from "permissions";
import BackToButton from "../../components/buttons/BackToButton";
import { ButtonSaveChanges } from "../../components/buttons";

const StateTopHeader = ({ stateInfo, tab, ...props }) => {
  const { id } = useParams();
  const path = `/states/${id}`;

  let title = `State: ${stateInfo ? stateInfo.name : ""}`;

  if (tab === "state-detail-broker") {
    title += " | Broker Licensing & Compliance";
  } else if (tab === "state-detail-provider") {
    title += " | Provider Licensing & Compliance";
  }

  return (
    <div
      style={{
        position: "fixed",
        top: "4rem",
        left: "0rem",
        width: "100%",
        zIndex: 25,
      }}
    >
      <Card style={{ border: "1px solid black", borderRadius: "0px" }}>
        <Card.Header
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignContent: "center",
            backgroundColor: "black",
          }}
        >
          <h5
            className="section-title navbar-title"
            style={{
              color: "white",
              alignItems: "center",
              marginBottom: 0,
              lineHeight: 2,
            }}
          >
            {title}
          </h5>
          <div style={{ display: "flex" }}>
            <BackToButton entity="States" url={URLS.STATES} />;
            {props.hasAdminPermission && <ButtonSaveChanges saveFn={props.saveFn} />}
          </div>
        </Card.Header>
      </Card>
      <h3 className={"header tertiary"}>
        <TabGroup
          style={{
            width: "100%",
          }}
        >
          <Tab link={`${path}/broker/`}>Broker Licensing & Compliance</Tab>
          <Tab link={`${path}/provider/`}>Provider Licensing & Compliance</Tab>
        </TabGroup>
      </h3>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    hasAdminPermission: isAdmin(state),
    stateInfo: activeStateSelector(state),
    tab: activeTabSelector(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
  setShouldSave,
  selectStateBrokerFile,
  selectStateProviderFile,
})(StateTopHeader);
