import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default ({ entity, url }) => {
  const history = useNavigate();

  return (
    <div style={{ display: "flex", justifyContent: "center", margin: 0 }}>
      <button
        className="btn btn--secondary navbar-button"
        onClick={() => history(url)}
        style={{
          width: "100%",
        }}
      >
        <FaArrowLeft /> Back to {entity}
      </button>
    </div>
  );
};
