import React from "react";

import { CREATE_CASE_FROM_FILES_MODAL } from "components/modals";
import FullPageModal from "../../../components/modals/FullPageModal";
import CaseCreateFromFilesForm from "./CaseCreateFromFilesForm";

export default props => {
  return (
    <FullPageModal modalType={CREATE_CASE_FROM_FILES_MODAL}>
      <CaseCreateFromFilesForm />
    </FullPageModal>
  );
};
