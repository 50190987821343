import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import { FormCollapsible, SubmitButton } from "components/forms";
import * as notifications from "notifications";
import { createCaseFromFiles } from "actions";
import { Form } from "react-bootstrap";
import MultipleFileDropZone from "../../../components/MultipleFileDropZone";

const CaseCreateFromFilesForm = ({ ...props }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={{}}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        console.log("onSubmit");
        try {
          await props.createCaseFromFiles(values);
        } catch (error) {
          console.log(error);
          notifications.error("Error");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <>
            <FormCollapsible onSubmit={handleSubmit} title={"New Case From Files"}>
              <Form.Row>
                <MultipleFileDropZone setFieldValue={setFieldValue} />
              </Form.Row>
              <SubmitButton defaultText={"Create Case Draft"} />
            </FormCollapsible>
          </>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, {
  createCaseFromFiles,
})(CaseCreateFromFilesForm);
