import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { FormCollapsible, Select } from "components/forms";
import {
  createAccountHierarchy,
  editAccountHierarchy,
  selectAccountCaseHierarchy,
  editCaseHierarchy,
  showModal,
} from "actions";
import {
  activeAccountIdSelector,
  activeAccountSelector,
  activeCaseIdSelector,
  managementChoicesSelector,
} from "reducers";
import CaseAccountHierarchyTable from "./CaseAccountHierarchyTable";
import WSelect from "../../../components/forms/selects/WindowedSelect";

const CaseAccountHierarchyForm = ({ ...props }) => {
  const handleAccountChange = option => {
    // Request the Account every time the dropdown changes
    props.selectAccountCaseHierarchy(option.value);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        account: props.activeAccountId,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {}}
    >
      {({ handleSubmit, values }) => {
        return (
          <FormCollapsible onSubmit={handleSubmit} title="Select Case Hierarchy">
            <Form.Row>
              <WSelect
                label="Select Account"
                name="account"
                options={props.accountChoices}
                onChange={handleAccountChange}
                placeholder="Select Account"
              />
            </Form.Row>
            <Form.Row>
              <CaseAccountHierarchyTable />
            </Form.Row>
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  return {
    activeCaseId: activeCaseIdSelector(state),
    activeAccountId: activeAccountIdSelector(state),
    accountChoices: choices.accounts?.getChoices({ sortByLabel: true }) || [],
    accountInfo: activeAccountSelector(state),
  };
};

export default connect(mapStateToProps, {
  createAccountHierarchy,
  editAccountHierarchy,
  selectAccountCaseHierarchy,
  showModal,
  editCaseHierarchy,
})(CaseAccountHierarchyForm);
