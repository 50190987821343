import React from "react";
import { connect } from "react-redux";

import { fetchPage, parseActiveSectionToEndpoint } from "actions";
import { pageSizeSelector, activeTabSelector } from "reducers";
import { ENDPOINTS_AND_PAGE_TO_PAGE_SELECTOR_MAPPING } from "../../actions/pagination/constants";

const Control = ({ children, className = "", ...props }) => {
  return (
    <button className={`control ${className}`} {...props}>
      {children}
    </button>
  );
};

const PageNumber = ({ isActive = false, idx, ...props }) => {
  return (
    <Control className={isActive && "active"} {...props}>
      {idx}
    </Control>
  );
};

const PagesDisplay = ({ pageCount, pageIndex, fetchPage, endpoint }) => {
  const deltaPages = 3; // how many to show forward/backwards from current page
  const pageNumbersToshow = [];
  if (pageIndex - deltaPages > 1) {
    pageNumbersToshow.push(
      <span key="ellipsis-1" className="ellipsis">
        ...
      </span>,
    );
  }

  let i = deltaPages;
  while (i > 0) {
    const page = pageIndex - i;
    if (page > 0) {
      pageNumbersToshow.push(<PageNumber key={page} idx={page} onClick={() => fetchPage(endpoint, page)} />);
    }
    i--;
  }

  pageNumbersToshow.push(<PageNumber key={pageIndex} idx={pageIndex} isActive />);

  i = 1;
  while (i <= deltaPages && parseInt(pageIndex) + i <= pageCount) {
    const page = parseInt(pageIndex) + i;
    pageNumbersToshow.push(<PageNumber key={page} idx={page} onClick={() => fetchPage(endpoint, page)} />);
    i++;
  }

  if (pageIndex + deltaPages < pageCount) {
    pageNumbersToshow.push(
      <span key="ellipsis-2" className="ellipsis">
        ...
      </span>,
    );
  }

  return pageNumbersToshow;
};

const PageSizeSelect = ({ pageSize, setPageSize }) => (
  <select
    value={pageSize}
    className="page-size"
    onChange={e => {
      setPageSize(Number(e.target.value));
    }}
  >
    {[10, 25, 50, 100].map(pageSize => (
      <option key={pageSize} value={pageSize}>
        Show {pageSize}
      </option>
    ))}
  </select>
);

const Pagination = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  pageIndex,
  setPageSize,
  gotoPage,
  pageSize,
  previousPage,
  nextPage,
  endpoint,
  ...props
}) => {
  if (pageCount === 0) return null;
  return (
    <div className="pagination">
      {pageCount > 1 && ( // only show pages when there are more than 1
        <>
          <Control onClick={() => props.fetchPage(endpoint, 1)} disabled={!canPreviousPage}>
            {"<<"}
          </Control>
          <Control onClick={() => props.fetchPage(endpoint, "PREVIOUS")} disabled={!canPreviousPage}>
            &larr; Prev
          </Control>
          <PagesDisplay pageCount={pageCount} pageIndex={pageIndex} fetchPage={props.fetchPage} endpoint={endpoint} />
          <Control onClick={() => props.fetchPage(endpoint, "NEXT")} disabled={!canNextPage}>
            Next &rarr;
          </Control>
          <Control onClick={() => props.fetchPage(endpoint, pageCount)} disabled={!canNextPage}>
            {">>"}
          </Control>
        </>
      )}
      {props.showPaginationResults ? (
        <PageSizeSelect
          pageSize={props.pageSizeStored}
          setPageSize={size => props.fetchPage(endpoint, pageIndex, size)}
        />
      ) : null}
    </div>
  );
};

const mapStateToProps = state => {
  const tab = activeTabSelector(state);
  const endpoint = parseActiveSectionToEndpoint(tab);
  let pageCount = ENDPOINTS_AND_PAGE_TO_PAGE_SELECTOR_MAPPING[endpoint]?.PAGES(state) || 0;
  let currentPage = ENDPOINTS_AND_PAGE_TO_PAGE_SELECTOR_MAPPING[endpoint]?.CURRENT(state) || 0;

  return {
    pageIndex: currentPage,
    pageCount: pageCount,
    pageSizeStored: pageSizeSelector(state),
    canPreviousPage: currentPage > 1,
    canNextPage: currentPage < pageCount,
    endpoint,
  };
};

export default connect(mapStateToProps, {
  fetchPage,
})(Pagination);
