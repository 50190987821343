import {
  SELECT_LIFE_SETTLEMENT_CASE,
  CLEAN_LIFE_SETTLEMENT_CASE_DATA,
  FETCH_LIFE_SETTLEMENT_CASE,
  FETCH_LIFE_SETTLEMENT_CHOICES,
  LOADING,
  CLEAN_LIFE_SETTLEMENT_SEARCH_DATA,
  CLEAN_CASES_PAGINATION_DATA,
  FETCH_LIFE_SETTLEMENT_CASE_ERROR,
} from "actions/types";
import advisorhq from "apis/advisorhq";
import * as notifications from "../../notifications";
import * as types from "../types";

export const selectLifeSettlementCase = id => {
  return { type: SELECT_LIFE_SETTLEMENT_CASE, payload: id };
};

export const fetchLifeSettlementCase = caseId => async dispatch => {
  dispatch({ type: LOADING, payload: { case: true } });
  try {
    const response = await advisorhq.get(`/life_settlement/api/cases/v2/${caseId}/`);
    dispatch({ type: FETCH_LIFE_SETTLEMENT_CASE, payload: response.data });
    if (response.data?.hierarchy) {
      dispatch({ type: types.SELECT_ACCOUNT_HIERARCHY, payload: response.data.hierarchy });
    } else {
      dispatch({ type: types.CLEAR_ACCOUNT_HIERARCHY });
    }
  } catch (e) {
    notifications.error(e.response.data.detail);
    dispatch({ type: FETCH_LIFE_SETTLEMENT_CASE_ERROR });
  }

  dispatch({ type: LOADING, payload: { case: false } });
};

export const cleanLifeSettlementCaseData = () => {
  return { type: CLEAN_LIFE_SETTLEMENT_CASE_DATA };
};

export const cleanLifeSettlementSearchData = () => {
  return { type: CLEAN_LIFE_SETTLEMENT_SEARCH_DATA };
};

export const cleanCasesPaginationData = () => {
  return { type: CLEAN_CASES_PAGINATION_DATA };
};

export const fetchLifeSettlementChoices = () => async (dispatch, getState) => {
  const response = await advisorhq.get("/life_settlement/api/choices/");
  dispatch({ type: FETCH_LIFE_SETTLEMENT_CHOICES, payload: response.data });
};

export const fetchAccountsListChoices = () => async (dispatch, getState) => {
  const response = await advisorhq.get("/api/accounts_list_choices/");
  dispatch({ type: FETCH_LIFE_SETTLEMENT_CHOICES, payload: response.data });
};
