import React from "react";
import { useDispatch } from "react-redux";

import ConfirmationModal from "components/modals/ConfirmationModal";
import { CONFIRM_FILE_ROTATION_MODAL } from "components/modals";
import { hideModal } from "actions";

export default props => {
  const dispatch = useDispatch();

  return (
    <ConfirmationModal
      modalType={CONFIRM_FILE_ROTATION_MODAL}
      onConfirm={() => {
        props.onConfirm();
        dispatch(hideModal(CONFIRM_FILE_ROTATION_MODAL));
      }}
    >
      Are you sure you want to automatically rotate all the pages in this document?
      <br />
      <br />
      This will replace the original file with a new version where all pages have auto-detected orientation.
    </ConfirmationModal>
  );
};
