import React from "react";
import { connect, useDispatch } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";
import { isEmpty } from "lodash";

import * as notifications from "../../../../notifications";
import {
  Select,
  EditorField,
  FormCollapsible,
  SubmitButton,
  DateInput,
  TextInput,
  DateTimeInput,
  HyperLinkText,
} from "components/forms";
import {
  createAccountTask,
  editAccountTask,
  fetchRecords,
  showModal,
  hideModal,
  fetchEmailTemplates,
  editAccountFromAccountTaskForm,
  unsetShouldSave,
} from "actions";
import {
  activeAccountIdSelector,
  activeTabSelector,
  storedRecordsFiltersSelector,
  userChoicesSelector,
  storedAccountRecordsFiltersSelector,
} from "reducers";
import { partial } from "../../../../utils";
import { ACCOUNT_STATUS, ACCOUNT_STATUS_MAP, TASK_STATUS, TASK_TYPES } from "../../constants";
import { FETCH_RECORDS_BY_TYPE_MAP, TaskTab } from "../../../records/constants";
import { URLS } from "../../../../constants";
import { useLocation } from "react-router-dom";
import { ACCOUNT_TASK_MODAL } from "../../../../components/modals";
import { getTaskObjId } from "../../../records/utils";
import { SendAccountEmailFromTemplateIcon } from "../../emails/SendEmailFromTemplateIcon";
import Collapsible from "../../../../components/Collapsible";

const AccountTaskForm = ({ record: activeTask, ...props }) => {
  if (!activeTask) return "";
  const accountTaskId = getTaskObjId(activeTask);
  let isSaved = accountTaskId;
  let title = isSaved ? "Edit Account Task" : "New Account Task";
  const location = useLocation();
  const dispatch = useDispatch();
  let initialValue = {};
  if (activeTask?.account_status) {
    initialValue = {
      value: activeTask?.account_status,
      label: ACCOUNT_STATUS_MAP[activeTask?.account_status] || "",
    };
  }
  let [statusValue, setStatusValue] = React.useState(initialValue);

  const defaultOwner = isSaved ? props.userId : props.userId;

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...activeTask,
        owner: activeTask.owner || defaultOwner,
        type: activeTask?.task_obj?.type || activeTask.type,
        status: activeTask?.task_obj?.status || activeTask.status,
        created_by: activeTask?.record_creator || activeTask.created_by,
        last_edited_by_name: activeTask?.last_editor || activeTask?.last_edited_by_name,
        work_email: activeTask.task_obj?.work_email || "",
        account_status: activeTask?.account_status,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        const method = isEmpty(activeTask) ? props.createAccountTask : partial(props.editAccountTask, accountTaskId);

        values.account = props.activeAccountId ? props.activeAccountId : activeTask?.task_obj?.account_id;

        try {
          if (values.record_id) {
            await props.editAccountFromAccountTaskForm(values.task_obj?.account, {
              work_phone: values.work_phone,
              cell_phone: values.cell_phone,
              work_email: values.work_email,
              notes: values.account_notes,
            });
          }
          setTimeout(async () => {
            await method(values);
          }, 500);
          notifications.clearAll();
          notifications.success("Account Task saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Task");
        }
        // Refresh tasks table - depends if we are in All Tasks or Account Tasks
        if (props.activeTab === "tasks" || props.activeTab === "account-tasks") {
          let type = TaskTab.ALL;
          let filterValues = props.recordsFilters;
          if (location.pathname.includes(URLS.ACCOUNT_TASKS)) {
            type = TaskTab.ACCOUNT;
            filterValues = props.accountRecordsFilters;
          }
          const fetch_action = FETCH_RECORDS_BY_TYPE_MAP[type];
          setTimeout(async () => {
            await props.fetchTasks({ ...filterValues }, fetch_action);
          }, 500);
        }
        dispatch(hideModal(ACCOUNT_TASK_MODAL));
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        const account_id = values.task_obj?.account;
        return (
          <FormCollapsible onSubmit={handleSubmit} title={title}>
            {values.record_id ? (
              <Collapsible title="Account Detail">
                <Form.Row>
                  <TextInput label="Work Phone" name="work_phone" />
                  <TextInput label="Cell Phone" name="cell_phone" />
                  <TextInput
                    label="Work Email"
                    name="work_email"
                    append={values.work_email ? <SendAccountEmailFromTemplateIcon accountId={account_id} /> : null}
                  />
                </Form.Row>
                <Form.Row>
                  <HyperLinkText
                    label="Record ID"
                    value={values.record_id || "---"}
                    url={account_id ? `/accounts/${account_id}/information/` : ""}
                    name="record_id"
                    inline={true}
                    disabled
                  />
                  <TextInput label="Record Name" name="record_name" disabled />
                  <Select
                    label="Account Status"
                    name="account_status"
                    defaultValue={statusValue}
                    options={ACCOUNT_STATUS}
                    onChange={async e => {
                      await props.editAccountFromAccountTaskForm(account_id, { status: e.value });
                      handleSubmit();
                    }}
                  />
                </Form.Row>
                <Form.Row>
                  <EditorField label="Account Notes" name="account_notes" autoFocus={false} />
                </Form.Row>
              </Collapsible>
            ) : null}
            <Collapsible title="Task Detail">
              <Form.Row>
                <Select label="Type" name="type" placeholder="Select Type" options={TASK_TYPES} />
                <Select label="Task Status" name="status" placeholder="Select Status" options={TASK_STATUS} />
                <Select
                  label="Owner"
                  name="owner"
                  placeholder="Select Owner"
                  options={props.userChoices.getChoices()}
                />
                <DateInput label="Due Date" name="due_date" />
              </Form.Row>
              <Form.Row>
                <TextInput label="Task Creator" name="created_by" disabled />
                <DateInput label="Create Date" name="date_created" disabled />
                <TextInput label="Last Edited By" name="last_edited_by_name" disabled />
                <DateTimeInput label="Last Edit Date" name="last_edit_date" disabled />
              </Form.Row>
              <Form.Row>
                <EditorField label="Task Notes" name="notes" autoFocus={false} />
              </Form.Row>
            </Collapsible>
            <SubmitButton />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    activeAccountId: activeAccountIdSelector(state),
    userChoices: userChoicesSelector(state),
    userId: state.auth.user_id,
    activeTab: activeTabSelector(state),
    recordsFilters: storedRecordsFiltersSelector(state),
    accountRecordsFilters: storedAccountRecordsFiltersSelector(state),
  };
};

export default connect(mapStateToProps, {
  createAccountTask,
  editAccountTask,
  fetchTasks: fetchRecords,
  showModal,
  fetchEmailTemplates,
  editAccountFromAccountTaskForm,
})(AccountTaskForm);
