import React from "react";
import { connect } from "react-redux";

import { showModal } from "../actions";

import { FaUpload } from "react-icons/fa";

const AddStateFileButton = ({ modal, selectFileFn, fileTypeName, ...props }) => {
  return (
    <div className="mb-3">
      <button
        className="btn btn--secondary navbar-button"
        onClick={async () => {
          selectFileFn(null);
          props.showModal(modal);
        }}
      >
        <FaUpload /> Add {fileTypeName} File
      </button>
    </div>
  );
};

export default connect(null, {
  showModal,
})(AddStateFileButton);
