import React from "react";
import { useField, useFormikContext } from "formik";
import { Form, InputGroup, Col } from "react-bootstrap";

import Label from "./Label";
import createMaskedInput from "./createMaskedInput";
import { validateLength, getValidationClassName } from "./validation";
import FieldErrors from "./FieldErrors";

const LENGTH = 4;

export default ({ name, label, md = "", ...props }) => {
  const [field, meta] = useField({ name, validate: validateLength(LENGTH) });
  const { setFieldValue } = useFormikContext();

  const d = /\d/;
  const mask = ["X", "X", "X", "-", "X", "X", "-", d, d, d, d];

  return (
    <Form.Group as={Col} sm="12" className={`col-md${md && "-" + md}`}>
      <InputGroup style={{ flexWrap: "no-wrap!important" }}>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <div style={{ marginLeft: 1, marginRight: 3 }}>{label}</div>
            {props.icon}
          </InputGroup.Text>
        </InputGroup.Prepend>
        <div style={{ flexGrow: "1" }}>
          {createMaskedInput({
            mask,
            field,
            setFieldValue,
            className: getValidationClassName(meta),
          })}
        </div>
        <FieldErrors meta={meta} />
      </InputGroup>
    </Form.Group>
  );
};
