import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import * as notifications from "../../../../notifications";
import { SubmitButton, EditorField } from "components/forms";
import { editAuction } from "actions";
import { auctionSelector } from "reducers";

const AuctionNotesForm = ({ auction, ...props }) => {
  const label1Style = {
    fontWeight: "bold",
    marginBottom: "-5px",
    paddingLeft: 15,
    backgroundColor: "#999999",
    color: "white",
    width: "48%",
    padding: "5px",
  };

  const label2Style = {
    marginLeft: "4%",
    ...label1Style,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={auction}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        try {
          await props.editAuction(auction.id, values);
          notifications.success("Auction saved successfully");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing auction");
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        return (
          <Form onSubmit={handleSubmit} title="Auction">
            <Form.Row style={{ padding: 0 }}>
              <EditorField label="Trading Notes" name="notes" autoFocus={false} />
              <EditorField label="Notes to Funder" name="notes_to_funder" autoFocus={false} />
            </Form.Row>
            <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} />
          </Form>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  return {
    auction: auctionSelector(state),
  };
};

export default connect(mapStateToProps, {
  editAuction,
})(AuctionNotesForm);
