import React, { useRef } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import { Select, TextInput, FormFilter, SubmitSearchButton, ClearFiltersButton } from "components/forms";
import {
  fetchAccountsListChoices,
  fetchLifeSettlementChoices,
  fetchManagerAgents,
  fetchTradingCases,
  storeTradingFiltersData,
  storeTradingStageFiltersData,
} from "actions";
import {
  agentsChoicesSelector,
  managementChoicesSelector,
  activeAgencySelector,
  storedTradingFiltersSelector,
} from "reducers";
import {
  biddingFormatChoices,
  caseRevenueModelTypeChoices,
  caseStatusChoices,
  marketTypeChoices,
  productTypeChoices,
  stageChoices,
} from "../../constants";

const TradingFilterForm = ({ fetchLifeSettlementChoices, fetchManagerAgents, currentFilters, ...props }) => {
  React.useEffect(() => {
    fetchLifeSettlementChoices();
    fetchManagerAgents();
    props.fetchAccountsListChoices();
  }, [fetchLifeSettlementChoices]);

  const handleStatusChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedStatusValues = selectedOptions.map(o => o.value).join(",");
      setStatus(commaSeparatedStatusValues);
    } else {
      setStatus(undefined);
    }
  };

  const handleRevenueModelChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedRevenueModelValues = selectedOptions.map(o => o.value).join(",");
      setRevenueModel(commaSeparatedRevenueModelValues);
    } else {
      setRevenueModel(undefined);
    }
  };

  const handleStageChange = selectedOptions => {
    if (selectedOptions) {
      const commaSeparatedStatusValues = selectedOptions.map(o => o.value).join(",");
      props.storeTradingStageFiltersData({ stage: commaSeparatedStatusValues });
    } else {
      props.storeTradingStageFiltersData({ stage: "" });
    }
  };

  const formRowStyle = {
    display: "flex",
    justifyContent: "space-between",
    padding: 0,
  };
  let status_initial = [];
  let stage_initial = [];
  let revenue_model_initial = [];

  let [status, setStatus] = React.useState();
  let [revenue_model, setRevenueModel] = React.useState();

  if (currentFilters && currentFilters.status) {
    let status_list = currentFilters && currentFilters.status && currentFilters.status.split(",");
    for (let status_code of status_list) {
      let stateData = caseStatusChoices.find(e => e.value === status_code);
      status_initial.push({ value: stateData.value, label: stateData.label });
    }
  }

  if (currentFilters && currentFilters.revenue_model) {
    let revenue_model_list = currentFilters && currentFilters.revenue_model && currentFilters.revenue_model.split(",");
    for (let revenue_model_code of revenue_model_list) {
      let revenueModelData = caseRevenueModelTypeChoices.find(e => e.value === revenue_model_code);
      revenue_model_initial.push({
        value: revenueModelData.value,
        label: revenueModelData.label,
      });
    }
  }

  if (currentFilters && currentFilters.stage && typeof currentFilters.stage === "string") {
    let stage_list = currentFilters && currentFilters.stage && currentFilters.stage.split(",");
    for (let stage_code of stage_list) {
      stage_initial.push({ value: stage_code, label: stage_code });
    }
  } else {
    stage_initial.push({ value: "Opportunity", label: "Opportunity" });
    stage_initial.push({ value: "Price Discovery", label: "Price Discovery" });
    stage_initial.push({ value: "In Closing", label: "In Closing" });
  }

  const parseStagesFromFilters = stages_list => {
    if (typeof stages_list === "string") {
      let parsed_stages = [];
      const splitted_stages = stages_list.split(",");
      for (let stage of splitted_stages) {
        parsed_stages.push({ value: stage, label: stage });
      }
      return parsed_stages;
    }
  };

  const parseRevenueModelsFromFilters = revenue_models_list => {
    if (typeof revenue_models_list === "string") {
      let parsed_revenue_models = [];
      const splitted_revenue_models = revenue_models_list.split(",");
      for (let revenue_model of splitted_revenue_models) {
        parsed_revenue_models.push({ value: revenue_model, label: revenue_model });
      }
      return parsed_revenue_models;
    }
  };

  const stageRef = useRef();
  const revenueModelRef = useRef();
  const statusRef = useRef();

  return (
    <Formik
      initialValues={{
        status,
        stage: (currentFilters && currentFilters.stage && parseStagesFromFilters(currentFilters.stage)) || "",
        revenue_model:
          (currentFilters &&
            currentFilters.revenue_model &&
            parseRevenueModelsFromFilters(currentFilters.revenue_model)) ||
          "",
        carrier: (currentFilters && currentFilters.carrier) || "",
        sales_representative: (currentFilters && currentFilters.sales_representative) || "",
        sales_manager: (currentFilters && currentFilters.sales_manager) || "",
        national_account_manager: (currentFilters && currentFilters.national_account_manager) || "",
        national_account: (currentFilters && currentFilters.national_account) || "",
        source: (currentFilters && currentFilters.source) || "",
      }}
      onSubmit={async (values, { setSubmitting }) => {
        let { highest_funder, insured_names__contains, stage, ...filters_param } = values;
        let parsedStatus = "";
        if (status) {
          parsedStatus = status.replaceAll(",", "__");
        }
        let parsedRevenueModel = "";
        if (revenue_model) {
          parsedRevenueModel = revenue_model.replaceAll(",", "__");
        }
        if (highest_funder) {
          highest_funder = props.activeFunderChoices?.getLabel(highest_funder);
        }
        if (insured_names__contains) {
          insured_names__contains = insured_names__contains.toLowerCase();
        }
        let stageParam = "";
        if (typeof currentFilters.stage === "string") {
          stageParam = currentFilters.stage;
        }
        props.storeTradingFiltersData({
          ...values,
          status: status,
          revenue_model: revenue_model,
          stage: stageParam,
        });
        await props.fetchTradingCases({
          ...filters_param,
          highest_funder,
          insured_names__contains,
          status__in: parsedStatus,
          revenue_model__in: parsedRevenueModel,
          stage: stageParam,
        });
        setSubmitting(false);
      }}
      onReset={async (values, { setSubmitting }) => {
        stage_initial = [];
        status_initial = [];
        revenue_model_initial = [];
        props.storeTradingFiltersData({});
        setSubmitting(false);
        statusRef.current.select?.clearValue();
        stageRef.current.select?.clearValue();
        revenueModelRef.current.select?.clearValue();
      }}
    >
      {({ handleSubmit, resetForm }) => {
        return (
          <FormFilter title={"Trading Case Search"} onSubmit={handleSubmit}>
            <Form.Row>
              <Select
                label="Sales Rep"
                name="sales_representative"
                placeholder="Select Sales Rep"
                options={props.salesRepChoices?.getChoices({ sortByLabel: true }) || []}
              />
              <Select
                label="Sales Manager"
                name="sales_manager"
                placeholder="Select Sales Manager"
                options={props.salesRepChoices?.getChoices({ sortByLabel: true }) || []}
              />
              <Select
                label="Nat Acc Manager"
                name="national_account_manager"
                placeholder="Select National Acc Manager"
                options={props.salesRepChoices?.getChoices({ sortByLabel: true }) || []}
              />
              <Select
                label="National Account"
                name="national_account"
                placeholder="Select National Account"
                options={props.nationalAccountChoices?.getChoices({ sortByLabel: true }) || []}
              />
            </Form.Row>
            <Form.Row>
              <Select
                label="Status"
                name="status"
                isMulti={true}
                onChange={handleStatusChange}
                placeholder="Select Status"
                options={caseStatusChoices}
                defaultValue={status_initial}
                submitRef={statusRef}
              />
              <Select
                label="Highest Funder"
                name="highest_funder"
                placeholder="Select Highest Funder"
                options={props.activeFunderChoices}
              />
              <Select
                label="Carrier"
                name="carrier"
                placeholder="Select Carrier"
                options={props.carrierChoices?.getChoices() || []}
              />
              <TextInput label="Insured Name" name="insured_names__contains" />
            </Form.Row>
            <Form.Row>
              <Select
                label="Stage"
                name="stage"
                placeholder="Select Stage"
                isMulti={true}
                onChange={handleStageChange}
                options={stageChoices}
                defaultValue={stage_initial}
                submitRef={stageRef}
                md={3}
              />
              <Select
                label="Revenue Model"
                name="revenue_model"
                placeholder="Select Revenue Model"
                options={caseRevenueModelTypeChoices}
                isMulti={true}
                onChange={handleRevenueModelChange}
                defaultValue={revenue_model_initial}
                submitRef={revenueModelRef}
                md={3}
              />
              <Select
                label="Market Type"
                name="market_type"
                placeholder="Select Market type"
                options={marketTypeChoices}
                md={3}
              />
              <Select
                label="Bidding Format"
                name="bidding_format"
                placeholder="Select Bidding Format"
                options={biddingFormatChoices}
                md={3}
              />
            </Form.Row>
            <Form.Row>
              <Select label="Source" name="source" placeholder="Select Source" options={props.sourceChoices} md={3} />
              <Select
                label="Product Type"
                name="product_type"
                placeholder="Select Product Type"
                options={productTypeChoices}
                md={3}
              />
              <TextInput label="Insured Age Low" name="insured_age__gte" md={3} />
              <TextInput label="Insured Age High" name="insured_age__lte" md={3} />
            </Form.Row>
            <Form.Row style={formRowStyle}>
              <div className={"col-md-6"}>
                <SubmitSearchButton style={{ maxHeight: "35px" }} />
                <ClearFiltersButton style={{ maxHeight: "35px" }} onClick={resetForm} />
              </div>
            </Form.Row>
          </FormFilter>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  const agentsChoices = agentsChoicesSelector(state);
  const currentFilters = storedTradingFiltersSelector(state);

  const sourceChoices = choices.accounts?.getChoices({ sortByLabel: true }) || [];
  sourceChoices.push({ value: null, label: "" });

  // Show only Funders that are Active
  let activeFunderChoices = [];
  for (const funderChoice of choices.funders.getChoices({ sortByLabel: true })) {
    if (funderChoice.active) {
      activeFunderChoices.push({
        value: funderChoice.value,
        label: funderChoice.label,
      });
    }
  }

  return {
    carrierChoices: choices.carriers,
    providerChoices: choices.case_providers,
    accountChoices: choices.accounts,
    nationalAccountChoices: choices.national_accounts,
    caseManagerChoices: agentsChoices.case_managers,
    pricingManagerChoices: agentsChoices.pricing_managers,
    auctionManagerChoices: agentsChoices.auction_managers,
    closingManagerChoices: agentsChoices.closing_managers,
    salesRepChoices: choices.case_sales_representatives,
    activeAgency: activeAgencySelector(state),
    activeFunderChoices,
    currentFilters,
    sourceChoices,
  };
};

export default connect(mapStateToProps, {
  fetchTradingCases,
  fetchLifeSettlementChoices,
  fetchManagerAgents,
  storeTradingFiltersData,
  storeTradingStageFiltersData,
  fetchAccountsListChoices,
})(TradingFilterForm);
