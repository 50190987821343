import React from "react";
import { FaAngleLeft } from "react-icons/fa";

export default ({ onClick, children, className, previous = false, ...props }) => {
  if (!previous) return "";
  return (
    <button className={`btn btn--secondary ${className}`} onClick={onClick} {...props}>
      <FaAngleLeft /> Prev
      {children}
    </button>
  );
};
