import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";

import { DateInput, FormCollapsible, Select, SubmitButton, TextInput } from "components/forms";
import { createSecuritiesIntermediary, editSecuritiesIntermediary, fetchFunderEntities, hideModal } from "actions";
import { Form } from "react-bootstrap";
import {
  activeFunderEntityIdSelector,
  activeFunderEntitySelector,
  activeFunderSelector,
  activeSecurityIntermediaryIdSelector,
  managementChoicesSelector,
} from "../../reducers";
import { partial } from "../../utils";
import * as notifications from "../../notifications";
import { SECURITY_INTERMEDIARY_MODAL } from "../../components/modals";

const SecurityIntermediaryForm = ({ ...props }) => {
  let initialValues = props.securityIntermediaryInfo || {};

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        let securitiesIntermediaryId = props.securityIntermediaryInfo && props.securityIntermediaryInfo.id;
        const method = securitiesIntermediaryId
          ? partial(props.editSecuritiesIntermediary, securitiesIntermediaryId)
          : props.createSecuritiesIntermediary;

        values.funder_entity_id = props.activeFunderEntityId;
        try {
          await method(values);
          await props.fetchFunderEntities(props.activeFunderId);
          props.hideModal(SECURITY_INTERMEDIARY_MODAL);
          notifications.success("Securities Intermediary saved");
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error saving Securities Intermediary");
        }
      }}
    >
      {({ handleSubmit, values, setFieldValue }) => {
        return (
          <div style={{ minHeight: 580 }}>
            <FormCollapsible
              onSubmit={handleSubmit}
              title={props.securityIntermediaryInfo ? "Edit Security Intermediary" : "New Security Intermediary"}
            >
              <Form.Row>
                <Select
                  label="Securities Intermediary"
                  name="intermediary_id"
                  options={props.escrowAgentChoices?.getChoices() || []}
                  emptyValue={null}
                />
                <TextInput label="SI Account Name" name="si_account_name" />
              </Form.Row>
              <Form.Row>
                <TextInput label="Policy Account Number" name="policy_account_number" />
                <TextInput label="SI Account Number" name="si_account_number" />
              </Form.Row>
              <Form.Row>
                <DateInput label="SACA Date" name="saca_date" />
                <TextInput label="Securities Intermediary Email" name="email" lowercase={true} md={6} />
              </Form.Row>
              <SubmitButton />
            </FormCollapsible>
          </div>
        );
      }}
    </Formik>
  );
};

const mapStateToProps = state => {
  const choices = managementChoicesSelector(state);
  const activeSecurityIntermediaryId = activeSecurityIntermediaryIdSelector(state);
  const activeFunderEntity = activeFunderEntitySelector(state);
  let securityIntermediaryInfo;
  if (activeSecurityIntermediaryId && activeFunderEntity) {
    console.log(activeFunderEntity, activeSecurityIntermediaryId);
    for (const sec_intermediary of activeFunderEntity.securities_intermediaries) {
      if (sec_intermediary.id === activeSecurityIntermediaryId) {
        securityIntermediaryInfo = sec_intermediary;
        break;
      }
    }
  }
  let funderInfo = activeFunderSelector(state);
  return {
    securityIntermediaryInfo,
    activeFunderId: funderInfo.id,
    activeFunderEntityId: activeFunderEntityIdSelector(state),
    escrowAgentChoices: choices.escrow_agents,
  };
};

export default connect(mapStateToProps, {
  editSecuritiesIntermediary,
  createSecuritiesIntermediary,
  fetchFunderEntities,
  hideModal,
})(SecurityIntermediaryForm);
