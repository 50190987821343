import { ACCOUNT_TYPES } from "./constants";

export const findAccountTypeFromLabel = label => {
  const foundAccountType = ACCOUNT_TYPES.find(accountType => accountType.label === label);
  return foundAccountType ? foundAccountType.value : null;
};

export const getEntityIdFromAccount = accountInfo => {
  if (accountInfo.type === findAccountTypeFromLabel("Entity")) {
    return accountInfo?.id;
  } else if (accountInfo.type === findAccountTypeFromLabel("Individual")) {
    return accountInfo?.associated_entity;
  } else {
    return null;
  }
};

export const parseDateTimeAsDate = dateString => (dateString ? String(dateString).split("T")[0] : "");
