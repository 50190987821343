import React from "react";
import { connect } from "react-redux";

import FullPageModal from "components/modals/FullPageModal";
import { ACCOUNT_LICENSE_MODAL } from "components/modals";
import LicenseForm from "./LicenseForm";
import SingleFileDropZone from "components/SingleFileDropZone";
import { activeLicenseSelector } from "reducers";
import { uploadLicenseFile } from "actions";

const LicenseModal = ({ activeLicense, ...props }) => {
  let isSaved = activeLicense && activeLicense.id;
  const submitRef = React.useRef();
  let title = isSaved ? "Edit License" : "New License";

  return (
    <FullPageModal modalType={ACCOUNT_LICENSE_MODAL} title={title} style={{ content: { overflow: "visible" } }}>
      <LicenseForm submitRef={submitRef} />
      {isSaved ? (
        <div>
          <SingleFileDropZone
            currentFile={activeLicense.name ? activeLicense : null}
            uploadFile={file => {
              props.uploadLicenseFile(file);
            }}
            fileDownloadUrl={`/api/license/${activeLicense.id}/download_file/`}
          />
        </div>
      ) : null}
    </FullPageModal>
  );
};

const mapStateToProps = state => {
  return {
    activeLicense: activeLicenseSelector(state) || {},
  };
};

export default connect(mapStateToProps, {
  uploadLicenseFile,
})(LicenseModal);
