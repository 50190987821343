import React from "react";
import { connect } from "react-redux";

import { showModal, downloadFile, selectAccountCarrierAppointment } from "actions";
import { activeAccountSelector, generalAgenciesSelector } from "reducers";
import { ACCOUNT_CARRIER_APPOINTMENT_MODAL, DELETE_ACCOUNT_CARRIER_APPOINTMENT_MODAL } from "components/modals";
import Table, { choiceCell, dateCell } from "components/table";
import { IconLinkViewOrEdit, IconLinkDelete } from "components/icons";
import { usStatesChoices } from "../../../../options";

const CarrierAppointmentTable = ({ activeAccount, ...props }) => {
  if (!activeAccount) {
    // Still didn't fetch data
    return null;
  }

  const columns = [
    {
      Header: "Action",
      className: "action",
      Cell: ({ row }) => {
        const id = row.original.id;
        return (
          <>
            <IconLinkViewOrEdit
              onClick={() => {
                props.selectAccountCarrierAppointment(row.original);
                props.showModal(ACCOUNT_CARRIER_APPOINTMENT_MODAL);
              }}
            />
            <IconLinkDelete
              onClick={() => {
                props.showModal(DELETE_ACCOUNT_CARRIER_APPOINTMENT_MODAL, { id });
              }}
            />
          </>
        );
      },
    },
    {
      Header: "Carrier Name",
      accessor: "carrier_name",
    },
    {
      Header: "Carrier Code Number",
      accessor: "code_number",
    },
    {
      Header: "Appointment Status",
      accessor: "appointment_status",
    },
    {
      Header: "Lines of Business",
      accessor: "lines_of_business",
    },
    {
      Header: "States",
      accessor: "states",
    },
    {
      Header: "General Agency",
      accessor: "general_agency",
      Cell: choiceCell(props.generalAgencies),
    },
    {
      Header: "Start Date",
      accessor: "start_date",
      Cell: dateCell,
    },
    {
      Header: "End Date",
      accessor: "end_date",
      Cell: dateCell,
    },
  ];

  return (
    <Table
      columns={columns}
      data={props.carrier_appointments}
      defaultSort={true}
      paginated={false}
      emptyMessage="No Carrier Appointments found"
    />
  );
};

const mapStateToProps = state => {
  const activeAccount = activeAccountSelector(state);
  const ca = activeAccount ? activeAccount.carrier_appointments : [];

  return {
    activeAccount,
    carrier_appointments: activeAccount ? activeAccount.carrier_appointments : [],
    generalAgencies: generalAgenciesSelector(state),
  };
};

export default connect(mapStateToProps, {
  showModal,
  selectAccountCarrierAppointment,
  downloadFile,
})(CarrierAppointmentTable);
