import React from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import { Form } from "react-bootstrap";

import {
  Select,
  EditorField,
  FormCollapsible,
  SubmitButton,
  DateInput,
  TextInput,
  DateTimeInput,
} from "components/forms";
import {
  activeCaseFileSelector,
  activeCaseSelector,
  beneficiariesSelector,
  doctorSelector,
  insuredListSelector,
  ownerListSelector,
  policySelector,
  userChoicesSelector,
  activeModalsSelector,
  activeTabSelector,
  storedRecordsFiltersSelector,
  storedCaseRequirementRecordsFiltersSelector,
} from "reducers";
import BooleanSelect from "components/forms/BooleanSelect";
import { isEmpty } from "lodash";
import { findObjectByPropertyValue, partial } from "utils";
import * as notifications from "notifications";
import {
  createCaseFile,
  editCaseFile,
  storeCaseFileCategory,
  storeCaseFileDescription,
  clearCaseFileDescription,
  clearCaseFileCategory,
  showModal,
  clearActiveCaseFile,
  fetchRecords,
} from "actions";
import CategorySelect from "./CategorySelect";
import moment from "moment";
import { caseDocumentStatusChoices, requiredOfChoices, STATUS_CASE_TABLE_RECEIVED_IGO, URLS } from "../../../constants";
import { FIND_DOCTOR_MODAL } from "../../../components/modals";
import { FETCH_RECORDS_BY_TYPE_MAP, TaskTab } from "../../records/constants";
import { isAdmin } from "permissions";

const CaseFileBaseForm = React.forwardRef((props, ref) => {
  const caseFile = props.caseFile;
  const activeCase = props.activeCase;
  const hasAdminPermission = props.hasAdminPermission;
  let {
    fr_id,
    category,
    sub_category,
    description,
    status,
    date_requested,
    date_received,
    document_date,
    follow_up_date,
    required_of,
    notes,
    file,
    file_redacted,
    funder_initial_review,
    funder_dd_review,
    responsible,
    created_by_name,
    last_edited_by_name,
    last_edit_date,
  } = caseFile;

  React.useEffect(() => {
    if (category) {
      props.storeCaseFileCategory(category);
    } else {
      props.clearCaseFileCategory();
    }
  }, [category]);

  React.useEffect(() => {
    if (description) {
      props.storeCaseFileDescription(description);
    } else {
      props.clearCaseFileDescription();
    }
  }, [description]);

  let today = moment();
  if (date_requested === undefined) {
    date_requested = today.format("YYYY-MM-DD");
  }
  if (follow_up_date === undefined) {
    follow_up_date = today.add(7, "d").format("YYYY-MM-DD");
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        fr_id,
        category,
        sub_category,
        description,
        status,
        date_requested,
        date_received,
        document_date,
        follow_up_date,
        required_of,
        notes,
        file,
        file_redacted,
        funder_initial_review,
        funder_dd_review,
        responsible,
        created_by_name,
        last_edited_by_name,
        last_edit_date,
      }}
      onSubmit={async (values, { setSubmitting, setErrors }) => {
        if (values.responsible) {
          if (typeof values.responsible === "object") {
            if (!values.responsible.value) {
              values.responsible = null;
            }
          }
        }
        const method =
          isEmpty(caseFile) || caseFile.id === undefined
            ? props.createCaseFile
            : partial(props.editCaseFile, caseFile.id);

        try {
          let hasFile = values.file;
          delete values.file; // File is saved through a different endpoint
          await method(values);

          // When the second Doctor Modal is active, don't show the notification
          let showNotification = true;
          if (props.activeModals) {
            let findDoctorModalActive = findObjectByPropertyValue(props.activeModals, "modalType", FIND_DOCTOR_MODAL);
            if (findDoctorModalActive) {
              showNotification = false;
            }
          }
          if (showNotification) {
            notifications.success("Case File saved successfully");
          }
          if (values.status === STATUS_CASE_TABLE_RECEIVED_IGO && !hasFile) {
            notifications.warn(
              <div>
                <b>Attention:</b> Please be aware this File and Requirement is marked as Received - IGO but there is no
                attachment.
                <br />
                Please correct as needed
              </div>,
            );
          }
        } catch (error) {
          setErrors(error.response.data);
          notifications.error("Error editing Case File");
        }
        // Refresh tasks table - depends if we are in All Tasks or Case Tasks
        if (props.activeTab === "tasks" || props.activeTab === "case-requirement-tasks") {
          let type = TaskTab.ALL;
          let filterValues = props.recordsFilters;
          if (location.pathname.includes(URLS.CASE_REQUIREMENT_TASKS)) {
            type = TaskTab.CASE_REQUIREMENT;
            filterValues = props.caseRequirementRecordsFilters;
          }
          const fetch_action = FETCH_RECORDS_BY_TYPE_MAP[type];
          await props.fetchTasks({ ...filterValues }, fetch_action);
        }
        setSubmitting(false);
      }}
    >
      {({ handleSubmit, values }) => {
        let title = "New Case File & Requirement";
        if (props.caseFileIsSaved) {
          title = "Edit Case File & Requirement";
        }
        return (
          <FormCollapsible onSubmit={handleSubmit} title={title}>
            <Form.Row>
              <CategorySelect
                values={values}
                policy={props.policy}
                insuredList={props.insuredList}
                ownerList={props.ownerList}
                beneficiaryList={props.beneficiaryList}
                requiredOfChoices={requiredOfChoices}
                ownerChoices={props.userChoices}
                caseFile={caseFile}
                activeCase={activeCase}
                doctorInfo={props.doctorInfo}
                showModal={props.showModal}
                submitRef={props.submitRef}
                disabled={!hasAdminPermission}
              />
            </Form.Row>
            <Form.Row>
              <TextInput label="FR-ID" name="fr_id" disabled />
              <Select
                label="Status"
                name="status"
                placeholder="Select Status"
                options={caseDocumentStatusChoices}
                disabled={!hasAdminPermission}
              />
              <Select
                label="Task Owner"
                name="responsible"
                placeholder="Select Owner"
                options={props.userChoices.getChoices({ sortByLabel: true })}
                disabled={!hasAdminPermission}
              />
              <Select
                label="Responsible Party"
                name="required_of"
                placeholder="Select Responsible Party"
                options={requiredOfChoices}
                disabled={!hasAdminPermission}
              />
            </Form.Row>
            <Form.Row>
              <DateInput label="Date Requested" name="date_requested" disabled={!hasAdminPermission} />
              <DateInput label="Date Received" name="date_received" disabled={!hasAdminPermission} />
              <DateInput label="Document Date" name="document_date" disabled={!hasAdminPermission} />
            </Form.Row>
            <Form.Row>
              <DateInput label="Follow Up Date" name="follow_up_date" md={4} disabled={!hasAdminPermission} />
              <BooleanSelect
                label="File Redacted"
                name="file_redacted"
                defaultValue={{ value: false, label: "No" }}
                disabled={true}
              />
            </Form.Row>
            <Form.Row>
              <TextInput label="Created By" name="created_by_name" disabled={true} />
              <TextInput label="Last Edited By" name="last_edited_by_name" disabled={true} />
              <DateTimeInput label="Last Edited Date" name="last_edit_date" disabled={true} />
            </Form.Row>
            <Form.Row>
              <EditorField label="Notes" name="notes" overrideDisabled={true} autoFocus={false} />
            </Form.Row>
            <SubmitButton innerRef={props.submitRef} style={{ display: "none" }} disabled={!hasAdminPermission} />
          </FormCollapsible>
        );
      }}
    </Formik>
  );
});

const mapStateToProps = state => {
  let userChoices = userChoicesSelector(state);
  const activeCase = activeCaseSelector(state) || {};
  const caseFile = activeCaseFileSelector(state) || {};

  return {
    caseFile,
    caseFileIsSaved: caseFile && caseFile.id,
    insuredList: insuredListSelector(state),
    ownerList: ownerListSelector(state),
    beneficiaryList: beneficiariesSelector(state),
    policy: policySelector(state),
    userChoices: userChoices,
    jurisdiction: activeCase && activeCase.jurisdiction,
    activeCase,
    hasAdminPermission: isAdmin(state),
    doctorInfo: doctorSelector(state),
    activeModals: activeModalsSelector(state),
    activeTab: activeTabSelector(state),
    recordsFilters: storedRecordsFiltersSelector(state),
    caseRequirementRecordsFilters: storedCaseRequirementRecordsFiltersSelector(state),
  };
};

export default connect(mapStateToProps, {
  createCaseFile,
  editCaseFile,
  storeCaseFileDescription,
  clearCaseFileDescription,
  storeCaseFileCategory,
  clearCaseFileCategory,
  showModal,
  activeModalsSelector,
  clearActiveCaseFile,
  fetchTasks: fetchRecords,
})(CaseFileBaseForm);
